import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { XmlService } from 'src/app/services/xml.service';

@Component({
  selector: 'app-ficha-adicional-ogp',
  templateUrl: './ficha-adicional-ogp.page.html',
  styleUrls: ['./ficha-adicional-ogp.page.scss'],
})
export class FichaAdicionalOGPPage implements OnInit {

  xml:any;
  fileSelected:any;
  constructor(private alertController:AlertController, private xmlService:XmlService, private modalCtrl:ModalController, public loadingController: LoadingController,navParams: NavParams) { 
      this.xml=navParams.get('xml');
  }

  ngOnInit() {
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  fileChange(event:any){
    this.fileSelected=event.target.files[0];
  }

  async guardar(){
    const loading = await this.loadingController.create({
      spinner:'lines',
      message:'Enviando ficha adicional...'
    });

    await loading.present();
    
    const formData= new FormData();
    formData.append('fechaOperacion',this.xml.fechaArchivo);
    formData.append('fechaDeposito',this.xml.fechaDeposito);
    formData.append('referencia',this.xml.referencia);
    formData.append('plaza',this.xml.plaza);
    formData.append('file',this.fileSelected, this.fileSelected.name);

    this.xmlService.guardarFicha(formData)
    .then((data:any)=>{
      loading.dismiss();
      this.mensajes("Respuesta", data.msg, `${data.code ? `Código: <strong>${data.code}</strong>` : ''}`)
      if(data.code == 107){
        this.modalCtrl.dismiss({
          'dismissed': true
        });
      }
    })
    .catch((data)=>{
      loading.dismiss();
      this.mensajes("Algo salió mal", "PROBLEMA CON EL WEBSERVICE", "Inténtalo más tarde")
    })
  }

  validarDatos():boolean{
    if(this.xml.fechaDeposito && this.xml.referencia && this.xml.plaza && this.fileSelected ){
      return false;
    }
    return true;
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
