import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';
@Injectable({
  providedIn: 'root'
})
export class EvidenciaService {
  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  guardarEvidencia(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/reporteGeneral/patrulla', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/reporteGeneral/patrulla', datos)
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  imagesMultiples(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/evidencias/imagesMultiples', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/evidencias/imagesMultiples', datos)
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  verEvidenciasXReporte(idReporte){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/evidencias/'+idReporte, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/evidencias/'+idReporte)
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  eliminarEvidencia(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.delete(CONFIG.API + '/api/evidencias/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.delete(CONFIG.API + '/api/evidencias/'+id )
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  modificarHoraEvidencia(id,hora){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/evidencias/'+id, {hora:hora}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/evidencias/'+id,{hora:hora} )
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  
}
