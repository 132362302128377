import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, LoadingController, AlertController } from '@ionic/angular';
import { InventarioService } from 'src/app/services/inventario.service';

@Component({
  selector: 'app-detalle-inventario',
  templateUrl: './detalle-inventario.page.html',
  styleUrls: ['./detalle-inventario.page.scss'],
})
export class DetalleInventarioPage implements OnInit {
  formularioDetalle: any = {}
  elementos: any = []
  constructor(public navParams: NavParams, public inventarioService: InventarioService,
    public modalCtrl:ModalController,public loadingController:LoadingController,
    public alertController:AlertController) {
    this.formularioDetalle = this.navParams.get('inventario')
    this.getData();
  }

  ngOnInit() {
  }

  async getData() {
    this.inventarioService.obtenerInventarioReporte({ tipoInventario: this.formularioDetalle.tipo, id: this.formularioDetalle.id })
      .then((elementos) => {
        this.elementos=elementos;
      })

  }

  getColor(i) {
    return (i % 2 == 0) ? 'rgba(200,200,200,.1)' : ''
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

 async eliminarInventario(inventario){
  const alert = await this.alertController.create({
    header: '¿Estás seguro de eliminar el elemento '+inventario.descripcion+' ?',
    message: '<strong>No podrá recuperarse</strong> y se tendrá que agregar nuevamente.',
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'Eliminar',
        handler: async () => {
          var loading = await this.loadingController.create({
            message: 'Eliminando elemento.....',
            spinner: "lines"
          });
          loading.present();
          this.inventarioService.eliminarHistorialID(inventario.id_historia_inventario)
          .then((data)=>{
            loading.dismiss(),
            this.getData();
            this.mensajes("Operación completada","","El elemento se ha borrado con éxito")
          })
          .catch(()=>{
            loading.dismiss();
            this.mensajes('Algo salió mal','','Vuelve a intentarlo nuevamente')
          })
        }
      }
    ]
  });

  await alert.present();

  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
