import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';
@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  save(datos){
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/firma-calificacion/',datos)
          .subscribe((prtgData: any) => {
            resolve(prtgData)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
  }

  getFirmaByVehiculo(id){
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + '/api/firma-calificacion/'+id)
        .subscribe((prtgData: any) => {
          resolve(prtgData)
        }, (err) => {
          console.log(err)
          reject(err)
        })
    })
  }

  update(datos){
    return new Promise((resolve, reject) => {
      this.http.put(CONFIG.API + '/api/firma-calificacion/',datos)
        .subscribe((prtgData: any) => {
          resolve(prtgData)
        }, (err) => {
          console.log(err)
          reject(err)
        })
    })
}

  toDataURL(url) {

    return new Promise((resolve,reject)=>{
      let xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
         resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    })
    
  }


}
