import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, LoadingController, ToastController, NavParams, ModalController } from '@ionic/angular';
import { ChatReporteService } from 'src/app/services/chat-reporte.service';
import { NoticacionesService } from 'src/app/services/noticaciones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-reporte',
  templateUrl: './chat-reporte.page.html',
  styleUrls: ['./chat-reporte.page.scss'],
})
export class ChatReportePage implements OnInit {
  @ViewChild("content", { static: false }) content: IonContent;

  formulario: any = {}
  mensajes: any = []
  usuario:any={}
  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    public navParams:NavParams,
    public chatReporteService:ChatReporteService,
    public notificacionesService:NoticacionesService,
    public router:Router,
    public modalController:ModalController
  ) {
    this.formulario.idReporte=this.navParams.get('idReporte');
    this.formulario.folio=this.navParams.get('folio');
    this.formulario.notificacion=this.navParams.get('notificacion');
    this.usuario=JSON.parse(window.localStorage.getItem('usuario'))

  }

  ngOnInit() {
    this.getMessages()
  }
  async getMessages() {
    let loading = await this.loadingController.create({
      message: 'Cargando mensajes, ¡Un momento por favor!',
      spinner: 'lines'
    })
    loading.present();
    
    await this.chatReporteService.obtenerMensajes(this.formulario.idReporte)
    .then((mensajes)=>{
      loading.dismiss();
        this.mensajes=mensajes
    })
    .catch((err)=>{
      loading.dismiss();
    })

    this.scrrollBotton();

  }

  async enviarMensaje() {
    const toast = await this.toastController.create({
      message: 'Mensaje enviado con éxito',
      duration: 3000
    });

   await this.chatReporteService.guardarMensaje(this.formulario)
    .then((data)=>{
      this.mensajes.push({nombre:this.usuario.nombre,mensaje:this.formulario.mensaje, 
        fechaMensaje:new Date(), id_usuario:this.usuario.id_usuario})
        toast.present();
        this.notificacionesService.notificacionMensaje(this.formulario)
        .then((data)=>{
          console.log(data)
        })
        this.formulario.mensaje = ''
    })
    .catch((err)=>{
      toast.message='Algo salió mal, vuelve a intentarlo'
      toast.present();
    })

    this.scrrollBotton()
    
  }

  async actualizar(event) {
    await this.getMessages();
    event.target.complete();
    this.scrrollBotton();

  }

  scrrollBotton() {
    this.content.scrollToBottom()
  }

  cerrar(){
      this.modalController.dismiss({
        dismiss:true
      })
  }
  abrirReporte(){
    this.cerrar();
    switch(this.usuario.id_tipo_usuario){
      case 2:this.router.navigate(['/menuCae/levantamiento/',this.formulario.idReporte]); break;
      case 3: this.router.navigate(['/menuAutocontrol/levantamiento/',this.formulario.idReporte]); break;
    }
  }

}
