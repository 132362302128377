import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';


@Injectable({
  providedIn: 'root'
})
export class RecorridoService {
  token = window.localStorage.getItem('token');
  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  //Obtiene recorrido por ID y usuario
  obtenerRecorrido(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/'+id)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  //Obtiene recorrido solamente por ID
  obtenerRecorridoXID(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/obtenerXID/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/obtenerXID/'+id)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerEvidenciasRecorrido(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/evidencias/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/evidencias/'+id)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  descargarReporte(id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/reportes-excel/reporte-recorridoPSAVV/', {id_recorrido:id}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/reportes-excel/reporte-recorridoPSAVV/', {id_recorrido:id},{
          responseType: 'arraybuffer', headers: { 'Authorization': 'Bearer ' + this.token }
        })
          .subscribe((info: any) => {
            resolve(info)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }


  obtenerRecorridoActual(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/recorrido/actual/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            console.log(data)
            if(data.data!=""){
              resolve(JSON.parse(data.data))
            }else{
              resolve(null)
            }
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/recorrido/actual/',datos)
          .subscribe((recorrido: any) => {
            resolve(recorrido)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerRecorridos(){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/')
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerRecorridosWithLimit(inicio){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/limit/'+inicio, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/limit/'+inicio)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerNumRegistros(){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/obtener/maximo', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/obtener/maximo')
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerRecorridosXFechaHora(fecha,hora){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/recorrido/cae/busquedaFechaHora/'+fecha+'/'+hora, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/recorrido/cae/busquedaFechaHora/'+fecha+'/'+hora)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  agregarRecorridoCae(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/recorrido/registroCAE', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/recorrido/registroCAE',datos)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}
