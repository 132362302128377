import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform, LoadingController, AlertController } from '@ionic/angular';
import CONFIG from '../../config';
import { InventarioService } from 'src/app/services/inventario.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';


@Component({
  selector: 'app-fotos-inventario',
  templateUrl: './fotos-inventario.page.html',
  styleUrls: ['./fotos-inventario.page.scss'],
})
export class FotosInventarioPage implements OnInit {
  slideOpts = {
    initialSlide: 1,
    speed: 400,
    height: 400
  };
  fotos: any = []
  registro: any = {}
  contador: number = 0;
  rutaImage: string = CONFIG.API + '/fotosInventario/';
  constructor(public platform: Platform, private camera: Camera, private modalCtrl: ModalController, private alertController: AlertController,
    private navParams: NavParams, private inventarioService: InventarioService, private loadingController: LoadingController) {

  }

  ngOnInit() {
    this.registro = this.navParams.get('row');
    let idInventario = this.registro.id_inventario_general;
    this.inventarioService.getFotosByInventario(idInventario)
      .then((fotos) => {
        this.fotos = fotos;
      })
      .catch(err => {
        console.log(err);
      })

  }

  async cerrar() {
    delete this.registro.images;
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  //Para dispositivos móviles
  async agregarFoto() {
    let cantidadMaxima = 4 - (this.fotos.length);
    if (cantidadMaxima != 0) {

      if (this.contador <= cantidadMaxima) {
        const options: CameraOptions = {
          quality: 10,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          mediaType: this.camera.MediaType.PICTURE,
          saveToPhotoAlbum: true,
        }

        this.camera.getPicture(options).then((imageData) => {
          if (this.contador === 0) {
            this.registro.images = new Array();
          }
          this.registro.images.push({ num: this.contador, base64: 'data:image/jpeg;base64,' + imageData });
          this.contador = this.contador + 1;
        }, (err) => {
          this.mensajes('Algo salió mal', '', JSON.stringify(err));
        });
      } else {//else contador
        this.mensajes('Inventario', '', `Solamente puedes tomar hasta ${cantidadMaxima} fotografías`);
      }
    } else { //YA no hay espacio para más fotos
      this.mensajes('Inventario', '', `Ya no puedes subir ninguna fotografía, solamente se pueden subir hasta 4 imágenes.`);

    }



  }
  //Para web
  getBase64(event) {
    let images = new Array();
    let cantidadFotos = event.target.files.length;
    let cantidadMaxima = 4 - (this.fotos.length);
    if (cantidadMaxima != 0) {
      if (cantidadFotos <= cantidadMaxima) {
        this.contador = cantidadFotos;
        for (let index = 0; index < cantidadFotos; index++) {
          let file = event.target.files[index];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            images.push({ num: index, base64: reader.result })
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
        }
        this.registro.images = images;
      } else {//else validación imagenes
        this.mensajes('Inventario', '', `Solamente puedes seleccionar ${cantidadMaxima} fotografías`);
      }
    } else { //YA no hay espacio para más fotos
      this.mensajes('Inventario', '', `Ya no puedes subir ninguna fotografía, solamente se pueden subir hasta 4 imágenes.`);
    }

  }

  async uploadImages() {
    let loading = await this.loadingController.create({
      spinner: 'lines',
      message: 'Subiendo imágenes...'
    })
    await loading.present();
    this.inventarioService.modificarInventario(this.registro.id_inventario_general, this.registro)
      .then((data) => {
        loading.dismiss();
        delete this.registro.images;
        this.mensajes('Operación completada', '', 'La información se ha actualizado con éxito')
        this.modalCtrl.dismiss({
          'dismissed': true
        });
      })
      .catch((err) => {
        this.mensajes('Algo salió mal', '', 'Intenta nuevamente más tarde')
        loading.dismiss();
      })
  }

  async eliminarFoto(id_foto) {
    const alert = await this.alertController.create({
      header: '¿Estás seguro de eliminar la fotografía?',
      message: 'Al eliminarla, podrás cargar una nueva fotografía.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Eliminar',
          handler: async () => {
            let loading = await this.loadingController.create({
              spinner: 'lines',
              message: 'Elimando imagen...'
            })
            await loading.present();
            this.registro.id_foto = id_foto;
            this.inventarioService.eliminarFotografia(this.registro.id_inventario_general, this.registro)
              .then((data) => {
                loading.dismiss();
                //Recargar las imágenes
                this.mensajes('Operación completa', '', 'Se ha elimado con éxito la fotografía');
                this.inventarioService.getFotosByInventario(this.registro.id_inventario_general)
                  .then((fotos) => {
                    this.fotos = fotos;
                  })
                  .catch(err => {
                    loading.dismiss();
                    console.log(err);
                  })
              })
              .catch((err) => {
                loading.dismiss();
                this.mensajes('Algo salió mal', '', 'Intenta nuevamente más tarde');
              })
          }
        }
      ]
    });

    await alert.present();
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }



}
