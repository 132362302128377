import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { RecorridoService } from 'src/app/services/recorrido.service';
import CONFIG from '../../../config';


@Component({
  selector: 'app-vista-recorrido',
  templateUrl: './vista-recorrido.page.html',
  styleUrls: ['./vista-recorrido.page.scss'],
})
export class VistaRecorridoPage implements OnInit {

  recorridos: any = []
  id: any
  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public recorridoService: RecorridoService,
    public alertController: AlertController,
    public navParams: NavParams
  ) {
    this.getData();
  }

  ngOnInit() {
  }
  getData() {
    this.id = this.navParams.get('id');
    this.recorridoService.obtenerEvidenciasRecorrido(this.id)
      .then((evidencias: any) => {
        evidencias.forEach(evidencia => {
          this.recorridos.push({ ruta: CONFIG.API + '/images/' + evidencia.evidencia, km: evidencia.km, hora: evidencia.hora, descripcion: evidencia.descripcion })
        });
      })



  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async descargar() {
    const loading = await this.loadingController.create({
      message: 'Descargando reporte.....',
      duration: 15000,
      spinner: 'lines'
    });
    loading.present();
    this.recorridoService.descargarReporte(this.id)
      .then((excel) => {
        loading.dismiss();
        this.downLoadFile(excel)
        this.mensajes("Operación exitosa", "", "Descargado")
      })
      .catch(() => {
        loading.dismiss();
        this.mensajes("Algo salió mal", "", "Inténtalo nuevamente más tarde")
      })

  }

  downLoadFile(data: any) {
    let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      console.log("Error")
    }
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }


}
