import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { InventarioService } from 'src/app/services/inventario.service';
import { LoadingController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-editar-inventario',
  templateUrl: './editar-inventario.page.html',
  styleUrls: ['./editar-inventario.page.scss'],
})
export class EditarInventarioPage implements OnInit {

  formulario: any = {};
  keys: any = [];
  constructor(private alertController: AlertController, private modalCtrl: ModalController,
     private loadingController: LoadingController, private navParams: NavParams, 
     private inventarioService: InventarioService, private geolocation:Geolocation) { }

  ngOnInit() {
    this.formulario = this.navParams.get('row');
    this.keys = Object.keys(this.formulario);
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async guardar() {
    let loading = await this.loadingController.create({
      spinner: 'lines',
      message: 'Actualizando información...'
    })
    await loading.present();
    this.inventarioService.modificarInventario(this.formulario.id_inventario_general, this.formulario)
      .then((data) => {
        this.mensajes('Actualizando', '', 'La información se ha actualizado con éxito')
        loading.dismiss();
        this.modalCtrl.dismiss({
          'accidente': data
        });
      })
      .catch((err) => {
        this.mensajes('Algo salió mal', '', 'Intenta nuevamente más tarde')
        loading.dismiss();
      })
  }
  async getLocation() {

    const loading = await this.loadingController.create({
      message: 'Obteniendo ubicacion.......',
      spinner:'lines'
    });
    await loading.present();
    this.geolocation.getCurrentPosition().then((resp) => {
      loading.dismiss();
      this.formulario.latitude = resp.coords.latitude;
      this.formulario.longitud = resp.coords.longitude;
    }).catch((error) => {
      loading.dismiss();
      this.mensajes("Ha ocurrido un error", "", "No se pudo obtener la ubicación intenta nuevamente ")
    });

  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
