import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { convertBase64ToBlob } from 'src/app/helpers/hepers';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import * as moment from 'moment';
@Component({
  selector: 'app-visualizar-pdf',
  templateUrl: './visualizar-pdf.page.html',
  styleUrls: ['./visualizar-pdf.page.scss'],
})
export class VisualizarPdfPage implements OnInit {

  public documento: any = {};
  public base64: string = '';
  public zoom: number = 0.6;
  public filename: string;
  constructor(private modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private platform: Platform,
    private file: File,
    private fileOpener: FileOpener,
    private navParams: NavParams) { }

  ngOnInit() {
    this.base64 = this.navParams.get('base64');
  }

  async cerrar() {
    this.modalController.dismiss();
  }

  masZoom() {
    if (this.zoom) {
      this.zoom += .1;
    }
  }

  menosZoom() {
    if (this.zoom > 0.3) {
      this.zoom -= .1;
    }
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  download() {
    if (this.platform.is('cordova')) {
      let filename = `downloadReport${moment().format('DDMMYYYYHHmmss')}.pdf`
      const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalRootDirectory + 'Download/';
      console.log(this.file.externalRootDirectory + '/Download')
      this.file.writeFile(writeDirectory,filename , convertBase64ToBlob(this.base64, 'data:application/pdf;base64'), { replace: true })
        .then(() => {
          this.fileOpener.open(writeDirectory + filename, 'application/pdf')
            .catch(() => {
              console.log('Error opening pdf file');
            });
        })
        .catch(() => {
          console.error('Error writing pdf file');
        });
    } else {
      let a = document.createElement("a"); //Create <a>
      a.href = this.base64
      a.download = this.filename; //File name Here
      a.click();
    }


  }

}
