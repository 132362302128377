import { Injectable } from '@angular/core';
import { SQLiteObject } from '@ionic-native/sqlite/ngx';
import { HTTP } from '@ionic-native/http/ngx'


@Injectable({
  providedIn: 'root'
})
export class IncidenciaMRServiceSqlliteService {
  db: SQLiteObject = null;

  constructor(private http: HTTP) { }

  setDatabase(db: SQLiteObject) {
    if (this.db === null) {
      this.db = db;
      //Crear tabla estandares y registros 
      this.createTableEstandares()
        .then(async (ok) => {
          this.getAllEstandares()
            //Si no hay registro de estándares se agregam
            .then((estandares) => {
              if (estandares.length == 0) {
                fetch('./assets/estandares.json')
                  .then(res => res.json())
                  .then(async (data) => {
                    for (let reg of data) {
                      await this.insertEstandares(reg);
                    }
                  })
              }
            })
        })
      this.createTableSubtipoEstandares()
        .then(async (ok) => {
          this.getAllSubEstandares()
            //Si no hay registro de sub estándares se agregam
            .then((subtipos) => {
              if (subtipos.length == 0) {
                fetch('./assets/subtipoEstandares.json')
                  .then(res => res.json())
                  .then(async (data) => {
                    for (let reg of data) {
                      await this.insertSubEstandares(reg)
                        .then(data => {
                        })
                    }
                  })
              }
            })
        })
      this.createTableUbicacion();
      this.createTableIncidencia();

    }
  }


  createTableUbicacion() {
    let sql = 'CREATE TABLE IF NOT EXISTS ubicacion(id_ubicacion INTEGER PRIMARY KEY AUTOINCREMENT,cadenamiento_inicial TEXT, cadenamiento_final TEXT, cuerpo TEXT, lado TEXT, latitud TEXT, longitud TEXT)';
    return this.db.executeSql(sql, []);
  }

  createTableEstandares() {
    let sql = 'CREATE TABLE IF NOT EXISTS estandares_mr(id_estandar INTEGER PRIMARY KEY AUTOINCREMENT,nombre TEXT, estandar TEXT)';
    return this.db.executeSql(sql, []);
  }

  createTableSubtipoEstandares() {
    let sql = 'CREATE TABLE IF NOT EXISTS subtipo_estandares_mr(id_subtipo INTEGER PRIMARY KEY AUTOINCREMENT,id_estandar INTEGER, plazo TEXT,descripcion TEXT,inicial TEXT )';
    return this.db.executeSql(sql, []);
  }

  createTableIncidencia() {
    let sql = 'CREATE TABLE IF NOT EXISTS incidenciaMR(id_incidencia_mr INTEGER PRIMARY KEY AUTOINCREMENT, id_subtipo INTEGER, id_ubicacion INTEGER, observaciones TEXT, fecha DATE, tipo INTEGER)';
    return this.db.executeSql(sql, []);
  }

  getAllEstandares() {
    let sql = 'SELECT * FROM estandares_mr';
    return this.db.executeSql(sql, [])
      .then(response => {
        let estandares = [];
        for (let index = 0; index < response.rows.length; index++) {
          estandares.push(response.rows.item(index));
        }
        return Promise.resolve(estandares);
      })
      .catch(error => Promise.reject(error));
  }

  getAllSubEstandaresXID(id, tipo) {
    id = parseInt(id)
    let sql = ''
    if (tipo) {
      sql = 'SELECT * FROM subtipo_estandares_mr WHERE id_estandar=? ';
    } else {
      sql = 'SELECT * FROM subtipo_estandares_mr WHERE id_estandar=? AND inicial="INMEDIATA"';
    }

    return this.db.executeSql(sql, [id])
      .then(response => {
        let estandares = [];
        for (let index = 0; index < response.rows.length; index++) {
          estandares.push(response.rows.item(index));
        }
        return Promise.resolve(estandares);
      })
      .catch(error => Promise.reject(error));
  }

  getAllSubEstandares() {
    let sql = 'SELECT * FROM subtipo_estandares_mr ';
    return this.db.executeSql(sql, [])
      .then(response => {
        let estandares = [];
        for (let index = 0; index < response.rows.length; index++) {
          estandares.push(response.rows.item(index));
        }
        return Promise.resolve(estandares);
      })
      .catch(error => Promise.reject(error));
  }

  getAllIncidencias() {
    let sql = 'SELECT * FROM incidenciaMR imr ' +
      'INNER JOIN ubicacion u ON u.id_ubicacion=imr.id_ubicacion ' +
      'INNER JOIN subtipo_estandares_mr smr ON smr.id_subtipo=imr.id_subtipo ' +
      'INNER JOIN estandares_mr e ON e.id_estandar=smr.id_estandar';
    return this.db.executeSql(sql, [])
      .then(response => {
        let estandares = [];
        for (let index = 0; index < response.rows.length; index++) {
          estandares.push(response.rows.item(index));
        }
        return Promise.resolve(estandares);
      })
      .catch(error => Promise.reject(error));
  }

  getIncidenciaMR(id) {
    let sql = 'SELECT imr.id_incidencia_mr, e.id_estandar as estandar, imr.tipo, smr.id_subtipo as idSubtipo,' +
      'imr.observaciones as observaciones, u.longitud, u.latitud, u.cadenamiento_inicial as km,' +
      'u.cadenamiento_final as kmFinal, u.cuerpo, u.lado FROM incidenciaMR imr ' +
      'INNER JOIN ubicacion u ON u.id_ubicacion=imr.id_ubicacion ' +
      'INNER JOIN subtipo_estandares_mr smr ON smr.id_subtipo=imr.id_subtipo ' +
      'INNER JOIN estandares_mr e ON e.id_estandar=smr.id_estandar ' +
      'WHERE id_incidencia_mr=?';
    return this.db.executeSql(sql, [id])
      .then(response => {
        let incidenciaMR = [];
        for (let index = 0; index < response.rows.length; index++) {
          incidenciaMR.push(response.rows.item(index));
        }
        return Promise.resolve(incidenciaMR[0]);
      })
      .catch(error => Promise.reject(error));
  }

  insertEstandares(estandar) {
    let sql = 'INSERT INTO estandares_mr(id_estandar, nombre, estandar) VALUES(?,?,?)';
    return this.db.executeSql(sql, [estandar.id_estandar, estandar.nombre, estandar.estandar]);
  }

  insertSubEstandares(subtipo) {
    let sql = 'INSERT INTO subtipo_estandares_mr(id_subtipo,id_estandar,plazo,descripcion,inicial ) VALUES(?,?,?,?,?)';
    return this.db.executeSql(sql, [subtipo.id_subtipo, subtipo.id_estandar, subtipo.plazo, subtipo.descripcion, subtipo.inicial]);
  }

  insertIncidenciaMR(formulario) {
    let sql = 'INSERT INTO incidenciaMR(id_subtipo,id_ubicacion,observaciones,fecha,tipo) VALUES(?,?,?,?,?)';
    return this.db.executeSql(sql, [formulario.idSubtipo, formulario.idUbicacion, formulario.observaciones, new Date(), 1]);
  }

  insertUbicacion(formulario) {
    let sql = 'INSERT INTO ubicacion(cadenamiento_inicial, cadenamiento_final, cuerpo, lado, latitud,longitud) VALUES(?,?,?,?,?,?)';
    return this.db.executeSql(sql, [formulario.km, formulario.kmFinal, formulario.cuerpo, formulario.lado, formulario.latitud, formulario.longitud]);
  }

  deleteIncidenciaMR(id) {
    let sql = 'DELETE FROM incidenciaMR WHERE id_incidencia_mr=?';
    return this.db.executeSql(sql, [id]);
  }

}
