import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';

@Injectable({
  providedIn: 'root'
})
export class AccidenteService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  obtenerTipoAccidentes() {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/accidente/tipo', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/accidente/tipo')
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerAccidentesXTipo(id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/accidente/tipo/' + id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/accidente/tipo/' + id)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  buscarAccidenteXID(id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/accidente/categoria/' + id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/accidente/categoria/' + id)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerCatalogoAccidentes() {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/accidente/', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/accidente/')
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  guardarAccidente(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/accidente/',datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/accidente/',datos)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  modificarAccidente(id,datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/accidente/'+id,datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/accidente/'+id,datos)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}


