import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { AseguradoraService } from '../../../services/aseguradora.service';

@Component({
  selector: 'app-registrar-aseguradora',
  templateUrl: './registrar-aseguradora.page.html',
  styleUrls: ['./registrar-aseguradora.page.scss'],
})
export class RegistrarAseguradoraPage implements OnInit {

  tipoAseguradora:any [] = [];
  public formulario: FormGroup;
  constructor(private aseguradoraService: AseguradoraService, public loadingController: LoadingController, public alertController: AlertController, public modalCtrl: ModalController, public formBuilder: FormBuilder, ) {
   this.crearFormulario();
   this.getTipoAseguradora();
  }

  ngOnInit() {
  }
    
  getTipoAseguradora(){
    this.aseguradoraService.obtenerTipoAseguradoras()
    .then((aseguradora: any[]) => {
      this.tipoAseguradora = aseguradora;
    })
  }
  
  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  crearFormulario() {
    this.formulario = this.formBuilder.group({
      nombres: ['', Validators.required],
      tipo: ['', Validators.required],
      fechaInicio: [''],
      fechaFin: [''],
      cobertura: [''],
      contrato: [''],
      poliza: [''],
    });
  }

  async guardar() {
      const loading = await this.loadingController.create({
        message: 'Guardando aseguradora.....',
        duration: 15000
      });
      await loading.present();
      this.aseguradoraService.guardarAseguradora(this.formulario.value)
        .then((data:any) => {
          if(data.error){
            this.mensajes("Error al guardar", "", "Intente nuevamente")
          }else if(data.ok){
            this.mensajes("Aseguradora guardada", "", "La aseguradora se ha registrado con éxito")
            this.modalCtrl.dismiss({
              'usuario': data
            });
          }
          loading.dismiss()
        })
        .catch((err)=>{
          console.log(err)
        }) 
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
