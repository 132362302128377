import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  guardarServicio(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/servicios/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/servicios/', datos)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerServicios(id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/servicios/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/servicios/'+id)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  obtenerServicio(idServicio) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/servicios/servicio/'+idServicio, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/servicios/servicio/'+idServicio)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  validarHoras(idReporte) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/servicios/validarHoras/'+idReporte, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/servicios/validarHoras/'+idReporte)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  recuperarServicio(id_servicio) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/servicios/recuperarServicio/'+id_servicio, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/servicios/recuperarServicio/'+id_servicio,{})
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  modificarServicio(datos,id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/servicios/'+id, datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/servicios/'+id,datos)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  cancelarServicio(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/servicios/cancelar/servicio/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/servicios/cancelar/servicio/',datos)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  recuperarHorasServicioyEmergencia(idReporte) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/servicios/seguro-911/'+idReporte,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/servicios/seguro-911/'+idReporte)
          .subscribe((horas: any) => {
            resolve(horas)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

}
