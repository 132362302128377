import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MaskDirective } from './mask.directive';


@NgModule({
	declarations: [
        MaskDirective
	],
	imports: [
		IonicModule
	],
	exports: [
        MaskDirective
	]
})
export class DirectivesModule { }
