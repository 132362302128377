import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { UsuarioService } from '../../../services/usuario.service'
import { NavParams } from '@ionic/angular'

@Component({
  selector: 'app-modificar-usuario',
  templateUrl: './modificar-usuario.page.html',
  styleUrls: ['./modificar-usuario.page.scss'],
})
export class ModificarUsuarioPage implements OnInit {
  public formulario: FormGroup
  cambiarContrasena: Boolean
  rol: String
  usuario: any = {}
  constructor(public alertController: AlertController, public loadingController: LoadingController, public navParams: NavParams, public formBuilder: FormBuilder, public usuarioService: UsuarioService, public modalCtrl: ModalController) {
    this.usuario = JSON.parse(window.localStorage.getItem('usuario'));
    this.crearFormulario();
    this.cargarDatos();
  }

  ngOnInit() {
  }
  crearFormulario() {
    this.formulario = this.formBuilder.group({
      nombres: ['', Validators.required],
      usuario: ['', Validators.required],
      password: [''],
      passwordRepeat: [''],
      rol: ['', Validators.required],
      cambiarContrasena: [''],
      idUsuario: ['']

    });
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  public cargarDatos() {
    this.usuarioService.obtenerUsuarioId(this.navParams.get('id'))
      .then((usuario: any) => {
        this.rol = usuario.tipo_usuario;
        this.formulario.controls['nombres'].setValue(usuario.nombre)
        this.formulario.controls['usuario'].setValue(usuario.usuario)
        this.formulario.controls['rol'].setValue(usuario.id_tipo_usuario)
        this.formulario.controls['idUsuario'].setValue(usuario.id_usuario)


      })

  }
  async modificar() {
    if (this.formulario.value.password == this.formulario.value.passwordRepeat) {
      const loading = await this.loadingController.create({
        message: 'Guardando usuario.....',
        duration: 15000
      });
      await loading.present();
      this.usuarioService.modificarUsuario(this.formulario.value)
        .then((usuario) => {
          loading.dismiss();
          this.mensajes("Usuario guardado", "", "El usuario se ha modificado con éxito")
          this.modalCtrl.dismiss({
            'usuario': usuario
          });
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  public cambiarTexto(e) {
    switch (e.target.value) {
      case '1':
        this.rol = 'PSAVV'
        break;
      case '2':
        this.rol = 'CAE'
        break;
      case '3':
        this.rol = 'AUTOCONTROL'
        break;
    }

  }
  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
