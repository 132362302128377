import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';
import CONFIG from 'src/app/config';
import { RatingService } from 'src/app/services/rating.service';




@Component({
  selector: 'app-firma-calificacion',
  templateUrl: './firma-calificacion.page.html',
  styleUrls: ['./firma-calificacion.page.scss'],
})
export class FirmaCalificacionPage implements OnInit {
  @ViewChild('userPad', { static: true }) public signaturePadUser: SignaturePad;
  @ViewChild('auxPad', { static: true }) public signaturePadAux: SignaturePad;

  signaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 350,
    canvasHeight: 200,
    backgroundColor: '#fff',
    penColor: '#000'
  };
  public rate: number = 0;
  public formulario: any = {};
  public usuario : any = {};
  constructor(private modalController: ModalController, private navParams: NavParams,
    private loadingController: LoadingController, private ratingService: RatingService,
    private alertController: AlertController) { 
      this.usuario = JSON.parse(localStorage.getItem('usuario'))
    }

  ngOnInit() {
    this.formulario.idVehiculo = this.navParams.get('id');
    if (this.navParams.get('idPersona')) {
      this.getData();
    }
  }

  getData() {
    this.ratingService.getFirmaByVehiculo(this.formulario.idVehiculo)
      .then((data: any) => {
        this.formulario.idCalificacion = data.id_calificacion;
        this.formulario.idPersona = data.id_persona;
        this.formulario.idVehiculo = data.id_vehiculo;
        this.formulario.nombre = data.nombre;
        this.formulario.correo = data.correo;
        this.formulario.ciudad = data.ciudad;
        this.formulario.telefono = data.telefono;
        this.formulario.otraInf = data.otraInf;
        this.formulario.comentarios = data.comentarios;
        this.formulario.rate = data.rate;

        //Set firmas
        this.ratingService.toDataURL(CONFIG.API + '/images/' + data.firma_usuario)
        .then((dataURL:string)=>{
          this.formulario.firmaUser = dataURL;
          this.signaturePadUser.fromDataURL(dataURL);
        })
        
        this.ratingService.toDataURL(CONFIG.API + '/images/' + data.firma_auxiliar)
        .then((dataURL:string)=>{
          this.formulario.firmaAux = dataURL;
          this.signaturePadAux.fromDataURL(dataURL);
        })
        
      })
  }

  async cerrar() {
    await this.modalController.dismiss();
  }

  ngAfterViewInit() {
    this.signaturePadAux.set('minWidth', 5);
    this.signaturePadAux.clear();

    this.signaturePadUser.clear();
    this.signaturePadUser.set('minWidth', 5);
  }

  drawComplete(type: string) {
    if (type == 'user') {
      this.formulario.firmaUser = this.signaturePadUser.toDataURL();
    } else {
      this.formulario.firmaAux = this.signaturePadAux.toDataURL();
    }
  }

  clear(type: string) {
    if (type == 'user') {
      this.signaturePadUser.clear();
      this.formulario.firmaUser = '';
    } else {
      this.signaturePadAux.clear();
      this.formulario.firmaAux = '';
    }
  }



  async guardarActualizar(){
    if(this.formulario.idCalificacion){
        this.actualizar();
    }else{
      this.guardar();
    }
  }

  async actualizar(){
    const loading = await this.loadingController.create({
      message: 'Actualizando datos...',
      spinner: 'lines'
    });
    await loading.present();

    this.ratingService.update(this.formulario)
      .then((ok) => {
        loading.dismiss();
        this.mensajes('Operación completada', '', 'Se ha actualizado la información con éxito')
      })
      .catch((err) => {
        console.log(err);
        loading.dismiss();
        this.mensajes('Algo salió mal', '', 'Inténtalo nuevamente');
      })
  }

  async guardar() {
    const loading = await this.loadingController.create({
      message: 'Guardando datos...',
      spinner: 'lines'
    });
    await loading.present();

    this.ratingService.save(this.formulario)
      .then((ok) => {
        loading.dismiss();
        this.mensajes('Operación completada', '', 'Se ha guardado la información con éxito')
        this.modalController.dismiss({insert:true});
      })
      .catch((err) => {
        console.log(err);
        loading.dismiss();
        this.mensajes('Algo salió mal', '', 'Inténtalo nuevamente');
      })
  }

  validateForm(): boolean {
    let me = this.formulario;
    if (me.firmaUser && me.firmaAux && me.rate && me.nombre) {
      return true;
    }

    return false;

  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
