import { Component } from '@angular/core';
import { Platform, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from '../app/services/authentication.service';
import { Router } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import CONFIG from './config';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { IncidenciaMRServiceSqlliteService } from './services/incidencia-mrservice-sqllite.service';
import { ChatReportePage } from './pages/chat-reporte/chat-reporte.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  public usuario: any = {};
  idReporte: any = 0;
  tipoNotificacion: any = '';
  page: any = ''
  folio: any = '';
  constructor(
    private platform: Platform,
    private appUpdate: AppUpdate,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private fcm: FCM,
    private sqlite: SQLite,
    private incidenciMRSqlLite: IncidenciaMRServiceSqlliteService,
    private vibration: Vibration,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public modalController: ModalController
  ) {
    this.usuario = JSON.parse(window.localStorage.getItem('usuario'))
    //Asignar variable para mostrar los nuevos cambios
    if (this.usuario) {
      if (typeof this.usuario.mostrarCambios === 'undefined') {
        this.usuario.mostrarCambios = true;
        localStorage.setItem('usuario', JSON.stringify(this.usuario))
      }
    }
    this.initializeApp();


  }
  verificarVersionApp(): boolean {
    if (this.platform.is('cordova')) {
      var validarVersion = false
      const updateUrl = CONFIG.API + '/actualizacion/update.xml';
      this.appUpdate.checkAppUpdate(updateUrl).then(() => {
        validarVersion = true
      })
        .catch(err => {
          validarVersion = false;
        })
      return validarVersion
    }

  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#ffffff');
      const loading = await this.loadingController.create({
        message: '¡Espere un momento por favor!, cargando aplicación...',
        spinner: "lines",
        duration: 1000
      });
      await loading.present();
      if (this.platform.is('cordova')) {
        this.fcm.onNotification().subscribe(async data => {
          this.idReporte = data.id;
          this.folio = data.folio;
          this.page = data.page;
          this.tipoNotificacion = data.tipo;
          this.redireccionamiento(loading)
          if (!data.wasTapped) {
            this.messagePersonalizado(data.id)
            this.redireccionamiento(loading);
          }
        });
      }
    })
      .then((loading: any) => {
        this.splashScreen.hide();
        //Crear Base de datos Local
        if (this.platform.is('cordova')) {
          this.createDatabase();
          this.verificarVersionApp()
        }
        this.redireccionamiento(loading);
      })

  }

  redireccionamiento(loading) {
    this.authenticationService.authState.subscribe(state => {
      if (state) {
        //Si existe una sesión activa, redireccionar a la ruta correspondiente por rol  
        if (this.usuario) {
          //Si se abre la aplicación por medio de la notificacion se redirecciona a su página corrspondiente
          if (this.idReporte != 0) {
            if (this.tipoNotificacion == 'mensajeReporte') {
              this.abrirModalMensajes();
            } else {
              this.router.navigate([this.page, this.idReporte]);
            }

          } else {
            switch (this.usuario.id_tipo_usuario) {
              case 1: this.router.navigate(['menuPatrulla/reportesActivos']); break;
              case 2: this.router.navigate(['menuCae/gestionReportes']); break;
              case 3: this.router.navigate(['menuAutocontrol/gestion-tabs/tabs/admision']); break;
              case 4: this.router.navigate(['menuOperacion/bitacoraLinea']); break;
              case 5: this.router.navigate(['menuMR/inspeccionesActivas']); break;
              case 6: this.router.navigate(['menuResidente/gestionInspeccionesR']); break;
              case 7: this.router.navigate(['menu-mantenimiento/gestion-siniestros']); break;
              case 8: this.router.navigate(['menuCae/gestionReportes']); break;
              case 9: this.router.navigate(['menu-enlace/gestion-siniestros']); break;
              case 10: this.router.navigate(['menu-contabilidad/gestion-siniestros']); break;
              case 11: this.router.navigate(['menu-gestor-operativo/gestion-ase-reportes']); break;
              default: this.authenticationService.logout(); break;

            }
          }
        }
      } else {
        if (loading) {
          loading.dismiss();
        }

        if (this.idReporte) {
          this.router.navigate(['login', this.idReporte])
        } else {
          this.router.navigate(['login'])
        }
      }
    });
  }

  async abrirModalMensajes() {
    const modal = await this.modalController.create({
      component: ChatReportePage,
      componentProps: { idReporte: this.idReporte, notificacion: true }
    });
    modal.onDidDismiss()
      .then((data) => {

      });

    return await modal.present();
  }

  async messagePersonalizado(id) {
    this.vibration.vibrate([2000, 1000, 2000]);
    let header = '';
    let message = '';
    if (this.tipoNotificacion == "inspeccion") {
      header = 'Se ha subido una nueva inspección con el folio No. ' + id;
      message = "Redireccionando...";
    } else if (this.tipoNotificacion == "incidencia") {
      header = 'Se ha subido una nueva incidencia con el folio No. ' + this.folio;
      message = "Redireccionando...";
    } else if (this.tipoNotificacion == "mensajeReporte") {
      header = 'Nuevo mensaje recibido';
      message = "Redireccionando...";
    }
    else if (this.tipoNotificacion == "revisionCAE") {
      header = 'Se ha finalizado con éxito una incidencia, ahora puedes descargar los reportes';
      message = "Redireccionando...";
    }
    else if (this.tipoNotificacion == "revisionUAC") {
      header = 'Tienes una nueva incidencia por revisar';
      message = "Redireccionando...";
    }
    else if (this.tipoNotificacion == "recordatorioMR") {
      header = 'Una incidencia está apunto de finalizar atiéndela AHORA';
      message = "Redireccionando...";
    }
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present()
      .catch((err) => {
        console.log(err)
      })

    setTimeout(() => {
      alert.dismiss()
    }, 4000);
  }

  //Crear base de datos local
  private createDatabase() {
    this.sqlite.create({
      name: 'appmroaam.db',
      location: 'default' // the location field is required
    })
      .then((db) => {
        console.log(db);
        this.incidenciMRSqlLite.setDatabase(db)
      })
      .catch(error => {
        console.error(error);
      });
  }
}
