import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavParams, LoadingController } from '@ionic/angular';
import { UsuarioService } from 'src/app/services/usuario.service';
import { RecorridoService } from 'src/app/services/recorrido.service';

@Component({
  selector: 'app-agregar-recorrido',
  templateUrl: './agregar-recorrido.page.html',
  styleUrls: ['./agregar-recorrido.page.scss'],
})
export class AgregarRecorridoPage implements OnInit {

  imagenInicio: any = {}
  imagenFin: any = {}
  formulario: any = {}
  usuariosPatrulleros: []
  constructor(public modalCtrl: ModalController,
    private usuarioService: UsuarioService,
    public navParams:NavParams,
    public loadingController:LoadingController,
    private recorridoService:RecorridoService,
    public alertController: AlertController) { }

  ngOnInit() {
    this.formulario.fecha=this.navParams.get('fecha')
    this.obtenerUsuariosPatrulleros();
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  obtenerUsuariosPatrulleros() {
    this.usuarioService.obtenerUsuariosPatrulla()
      .then((usuarios: any) => {
        this.usuariosPatrulleros = usuarios;
      })
  }

  fileChange(element, tipo) {
    if (tipo === "inicio") {
      this.imagenInicio = element.target.files[0];
    } else if(tipo==='fin'){
      this.imagenFin = element.target.files[0];
    }

  }

  async guardar() {
    if (this.validarTipoArchivos()) {
    const loading = await this.loadingController.create({
      spinner:'lines',
      message:'Guardando recorrido...'
    }) 

    await loading.present();
      //Creación de formData
      const formData = new FormData();
      formData.append('imagenInicio', this.imagenInicio);
      formData.append('imagenFin', this.imagenFin);
      formData.append('fecha',this.formulario.fecha);
      formData.append('hora_inicio',this.formulario.hora_inicio);
      formData.append('hora_fin',this.formulario.hora_fin);
      formData.append('unidad',this.formulario.unidad);
      formData.append('turno',this.formulario.turno);
      formData.append('id_usuario',this.formulario.id_usuario);

      this.recorridoService.agregarRecorridoCae(formData)
      .then((data:any)=>{
        loading.dismiss();
        this.modalCtrl.dismiss({
          'idRecorrido': data.insertId
        })
        .catch((err)=>{
          loading.dismiss();
         this.mensajes("Algo salió mal", "", "Verifica tu conexión a internet e intenta de nuevo")

        })
      })

    } else {
      this.mensajes("Selecciona imágenes", "", "Alguna de las imágenes que intentas subir no es formato <strong>JPEG o PNG</strong>")
    }

  }

  validarFormulario(): boolean {
    if (this.formulario.fecha && this.formulario.hora_inicio && this.formulario.hora_fin && this.formulario.unidad
      && this.formulario.turno && this.formulario.id_usuario && this.imagenFin.name && this.imagenInicio.name) {
      return false;
    }
    return true;
  }

  validarTipoArchivos(): boolean {
    let bandera = false;
    //Validar tipo de archivo que se subió
    if ((this.imagenInicio.type === 'image/jpeg' || this.imagenInicio.type === 'image/png') &&
      (this.imagenFin.type === 'image/jpeg' || this.imagenFin.type === 'image/png')) {
      bandera = true;
    }
    return bandera;
  }


  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
