import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';
@Injectable({
  providedIn: 'root'
})
export class InventarioService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }
  obtenerInventarioID(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/inventario/inventarioID', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/inventario/inventarioID',datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  eliminarHistorialID(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.delete(CONFIG.API + '/api/inventario/eliminarHistorial/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.delete(CONFIG.API + '/api/inventario/eliminarHistorial/'+id)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerHistorialInventario(){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/all/historial', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/all/historial')
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getDataInventario(id_inventario, tabla){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/'+ id_inventario +'?tipo='+ tabla, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/'+ id_inventario +'?tipo='+ tabla)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }


  
  obtenerInventarioReporte(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/inventario/inventarioReporte', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/inventario/inventarioReporte',datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  obtenerTipoInventario(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario/tipoInventario/'+id,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario/tipoInventario/'+id)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  obtenerInventarioCerca(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/inventario/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/inventario/',datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  insertarInventario(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/inventario/insertarInventario', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/inventario/insertarInventario',datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getTiposInventario(clasificacion:string){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/query?clasificacion='+clasificacion, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            reject(err)
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/query?clasificacion='+clasificacion)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  getInventariosXTipo(tipo:string){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/query?tipo='+tipo, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/query?tipo='+tipo)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  modificarInventario(id,datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/inventario-mro/modificarInventario/'+id,datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            reject(err)
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/inventario-mro/modificarInventario/'+id,datos)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  busquedaXTipo(tipo:string){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/busqueda/'+tipo, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/busqueda/'+tipo)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  guardarInventario(datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/inventario-mro/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/inventario-mro', datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getFotosByInventario(id){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/fotos/'+id ,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/fotos/'+id)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  eliminarFotografia(id_inventario_general, datos){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/inventario-mro/foto/'+id_inventario_general ,datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/inventario-mro/foto/'+id_inventario_general, datos)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getCarrilesByPlaza(idPlaza){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/obac/carrilByPlaza/'+idPlaza ,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/obac/carrilByPlaza/'+idPlaza)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getAreasByPlaza(idPlaza){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/obac/areasByPlaza/'+idPlaza ,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/obac/areasByPlaza/'+idPlaza)
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  getBienesByArea(idArea, nombre=''){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/obac/bienesByArea/'+idArea+'?nombre='+nombre ,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/obac/bienesByArea/'+idArea+'?nombre='+nombre )
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  getEquiposByCarril(idCarril, nombre='',plaza){
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/inventario-mro/obac/equiposByCarril/'+idCarril+'?nombre='+nombre+'&plaza='+plaza ,{}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
            reject(err)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/inventario-mro/obac/equiposByCarril/'+idCarril+'?nombre='+nombre+'&plaza='+plaza )
          .subscribe((inventario: any) => {
            resolve(inventario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }


}

