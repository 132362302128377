import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule',canActivate: [AuthGuardLoginService] },
  { path: 'login/:id', loadChildren: './pages/login/login.module#LoginPageModule',canActivate: [AuthGuardLoginService] },
  { path: 'menuPatrulla', loadChildren: './pages/psavv/menu-patrulla/menu-patrulla.module#MenuPatrullaPageModule',
  canActivate: [AuthGuardService]
 },
  { path: 'menuCae', loadChildren: './pages/cae/menu-cae/menu-cae.module#MenuCaePageModule', canActivate: [AuthGuardService] },
  { path: 'menuAutocontrol', loadChildren: './pages/autocontrol/menu-autocontrol/menu-autocontrol.module#MenuAutocontrolPageModule',canActivate: [AuthGuardService] },
  { path: 'menuOperacion', loadChildren: './pages/operacion/menu-operacion/menu-operacion.module#MenuOperacionPageModule',canActivate: [AuthGuardService] },
  { path: 'menuMR', loadChildren: './pages/mr/menu-mr/menu-mr.module#MenuMRPageModule',canActivate: [AuthGuardService] },
  { path: 'menuResidente', loadChildren: './pages/residente/menu-residente/menu-residente.module#MenuResidentePageModule',canActivate: [AuthGuardService] },
  { path: 'menu-mantenimiento', loadChildren: './pages/mantenimiento/menu-mantenimiento/menu-mantenimiento.module#MenuMantenimientoPageModule',canActivate: [AuthGuardService] },
  { path: 'menu-enlace', loadChildren: './pages/rol-enlace/menu-enlace/menu-enlace.module#MenuEnlacePageModule' },
  { path: 'menu-contabilidad', loadChildren: './pages/rol-contabilidad/menu-contabilidad/menu-contabilidad.module#MenuContabilidadPageModule' },  { path: 'ase-gestion-reportes', loadChildren: './pages/aseguradora/ase-gestion-reportes/ase-gestion-reportes.module#AseGestionReportesPageModule' },
  { path: 'menu-gestor-operativo', loadChildren: './pages/aseguradora/menu-gestor-operativo/menu-gestor-operativo.module#MenuGestorOperativoPageModule' },





  

  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
