import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { RecorridoService } from '../../../services/recorrido.service';

@Component({
  selector: 'app-popover-recorrido',
  templateUrl: './popover-recorrido.component.html',
  styleUrls: ['./popover-recorrido.component.scss'],
})
export class PopoverRecorridoComponent implements OnInit {

  recorridos:any=[]
  constructor(public navParams:NavParams, 
    private popooverController:PopoverController,
    private recorridoService:RecorridoService) { }

  ngOnInit() {
    this.buscarRecorridos();
  }

  buscarRecorridos(){
    let fecha=this.navParams.get('fecha');
    let hora=this.navParams.get('hora_not');
    console.log(fecha,hora)
    this.recorridoService.obtenerRecorridosXFechaHora(fecha,hora)
    .then((recorridos)=>{
        this.recorridos=recorridos;
    })
  }

  async seleccionarRecorrido(id){

    this.popooverController.dismiss({
       idRecorrido:id
    })
  }

}
