import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { CatPlazaCobroService } from 'src/app/services/cat-plaza-cobro.service';
import { CatCarrilService } from 'src/app/services/cat-carril.service';

@Component({
  selector: 'app-registrar-carriles',
  templateUrl: './registrar-carriles.page.html',
  styleUrls: ['./registrar-carriles.page.scss'],
})
export class RegistrarCarrilesPage implements OnInit {
  plazaCobro:any [] = [];
  public formulario: FormGroup;
  constructor(private plazaCobroService: CatPlazaCobroService, private carrilService: CatCarrilService ,public loadingController: LoadingController, public alertController: AlertController, public modalCtrl: ModalController, public formBuilder: FormBuilder, ) {
    this.crearFormulario();
    this.getPlazaCobro();
   }
 
   ngOnInit() {
   }
     
   getPlazaCobro(){
     this.plazaCobroService.getPlazas().subscribe((plazas: any[]) => {
       this.plazaCobro = plazas;
     })
   }
   
   async cerrar() {
     this.modalCtrl.dismiss({
       'dismissed': true
     });
   }
 
   crearFormulario() {
     this.formulario = this.formBuilder.group({
       plaza: ['', Validators.required],
       nombre: ['', Validators.required],
     });
   }
 
   async guardar() {
       const loading = await this.loadingController.create({
         message: 'Guardando carril.....',
         duration: 15000
       });
       await loading.present();
       this.carrilService.guardarCarril(this.formulario.value)
         .then((data:any) => {
           if(data.error){
             this.mensajes("Error al guardar", "", "Intente nuevamente")
           }else if(data.ok){
             this.mensajes("Carril guardado", "", "El carril se ha registrado con éxito")
             this.modalCtrl.dismiss({
               'usuario': data
             });
           }
           loading.dismiss()
         })
         .catch((err)=>{
           console.log(err)
         }) 
   }
 
   async mensajes(header, subHeader, message) {
     const alert = await this.alertController.create({
       header: header,
       subHeader: subHeader,
       message: message,
       buttons: ['Aceptar']
     });
 
     await alert.present();
   }
 

}
