import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { AseguradoraService } from 'src/app/services/aseguradora.service';
import { NavParams } from '@ionic/angular'

@Component({
  selector: 'app-modificar-aseguradora',
  templateUrl: './modificar-aseguradora.page.html',
  styleUrls: ['./modificar-aseguradora.page.scss'],
})
export class ModificarAseguradoraPage implements OnInit {
  public formulario: FormGroup
  tipoAseguradora: any = []
  constructor(public alertController: AlertController, public loadingController: LoadingController, public navParams: NavParams, public formBuilder: FormBuilder, public aseguradora_Service: AseguradoraService, public modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.crearFormulario();
    this.cargarDatos();
    this.getTipoAseguradora();
  }

  getTipoAseguradora() {
    this.aseguradora_Service.obtenerTipoAseguradoras()
      .then((aseguradora) => {
        this.tipoAseguradora = aseguradora;
      })
  }

  crearFormulario() {
    this.formulario = this.formBuilder.group({
      nombres: ['', Validators.required],
      tipo: [0, Validators.required],
      fechaInicio: [''],
      fechaFin: [''],
      cobertura: [''],
      contrato: [''],
      poliza: [''],
    });
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  public cargarDatos() {
    this.aseguradora_Service.obtenerAseguradoraId(this.navParams.get('id'))
      .then((aseguradora: any) => {
        this.formulario.controls['nombres'].setValue(aseguradora[0].nombre)
        this.formulario.controls['tipo'].setValue(aseguradora[0].tipo_aseguradora)
        this.formulario.controls['fechaInicio'].setValue(aseguradora[0].fecha_inicio)
        this.formulario.controls['fechaFin'].setValue(aseguradora[0].fecha_fin)
        this.formulario.controls['cobertura'].setValue(aseguradora[0].cobertura)
        this.formulario.controls['contrato'].setValue(aseguradora[0].contrato)
        this.formulario.controls['poliza'].setValue(aseguradora[0].poliza)
      })
  }
  async modificar() {
    const loading = await this.loadingController.create({
      message: 'Guardando aseguradora.....',
      duration: 15000
    });
    await loading.present();
    this.aseguradora_Service.modificarAseguradora(this.formulario.value, this.navParams.get('id'))
      .then((aseguradora) => {
        loading.dismiss();
        this.mensajes("Aseguradora guardada", "", "La aseguradora se ha modificado con éxito")
        this.modalCtrl.dismiss({
          'usuario': aseguradora
        });
      })
      .catch((err) => {
        console.log(err)
      })
    loading.dismiss()
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
