import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from '../socket.service';
import { UsuarioService } from './usuario.service';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private platform: Platform,
    public toastController: ToastController,
    public sockets: SocketService,
    public usuarioService: UsuarioService,

  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }

  ifLoggedIn() {
    let token=localStorage.getItem('token');
    if(token){
      this.authState.next(true);
    }else{
      this.authState.next(false);
    }
  
  }


  login(token) {
    if (token) {
      this.authState.next(true);
    }
  }

  logout() {
    localStorage.clear()
    this.sockets.desconectar();
    this.authState.next(false);
    if (this.platform.is('cordova')) {
      navigator['app'].exitApp();
    } else {
      window.location.reload();
    }
  }

  isAuthenticated() {
    return this.authState.value;
  }

}
