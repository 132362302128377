import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx'
import CONFIG from '../config'
import { Platform } from '@ionic/angular';
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }
  
  obtenerUsuario() {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/fetchUsuario', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/fetchUsuario')
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }

  obtenerUsuariosMR() {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/obtener/usuariosMR', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/obtener/usuariosMR')
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  obtenerUsuariosO() {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/obtener/usuariosO', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/obtener/usuariosO')
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  obtenerUsuariosAseguradora() {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/obtener/usuarioAseguradora', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/obtener/usuarioAseguradora')
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  obtenerUsuarioId(id) {

    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/' + id)
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }


  guardarUsuario(datos) {

    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/usuario/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/usuario/', datos)
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }

  obtenerUsuarios() {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/')
          .subscribe((usuarios: any) => {
            resolve(usuarios)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  obtenerUsuariosPatrulla() {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/usuario/usuarioPatrulla/', {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/usuario/usuarioPatrulla/')
          .subscribe((usuarios: any) => {
            resolve(usuarios)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  

  modificarUsuario(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/usuario/'+ datos.idUsuario, datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/usuario/' + datos.idUsuario, datos)
          .subscribe((usuarios: any) => {
            resolve(usuarios)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }

  eliminarUsuario(id) {

    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.delete(CONFIG.API + '/api/usuario/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.delete(CONFIG.API + '/api/usuario/' + id)
          .subscribe((usuarios: any) => {
            resolve(usuarios)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }

  actualizarToken(datos) {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/usuario/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/usuario/',datos)
          .subscribe((usuario: any) => {
            resolve(usuario)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }


}
