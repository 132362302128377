import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import CONFIG from '../../config';
import { EvidenciasMRService } from 'src/app/services/evidencias-mr.service';

@Component({
  selector: 'app-visualizar-imagen',
  templateUrl: './visualizar-imagen.page.html',
  styleUrls: ['./visualizar-imagen.page.scss'],
})
export class VisualizarImagenPage implements OnInit {

  evidencia: any = {}
  idincidenciaMR = 0
  evidenciasMR = []
  rutaImg = CONFIG.API + '/imagesMR/'

  constructor(public navParams: NavParams, public modalCtrl: ModalController, public evidenciaMR: EvidenciasMRService) {
    this.cargarDatos()
  }

  ngOnInit() {
  }

  cargarDatos() {
    //console.log(this.navParams);
     this.evidencia = this.navParams.get('evidencia');
    this.idincidenciaMR = this.navParams.get('idincidenciaMR');
    if (!this.evidencia) {
      this.evidenciaMR.obtenerEvidenciasMR(this.idincidenciaMR)
        .then((imagenes: any) => {
          this.evidenciasMR = imagenes
        })
    } else {
      if (this.evidencia.evidenciaMR) {
        this.evidencia.ruta = CONFIG.API + '/imagesMR/' + this.navParams.get('evidencia').imagen;
      } else {
        this.evidencia.ruta = CONFIG.API + '/images/' + this.navParams.get('evidencia').imagen;
      }
    }
  
  }


  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
