import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InformacionCompartidaService {

  constructor() { }

  public idReporte=0;
  public idRecorrido=0;
  //status variable para saber si ya está finalizado
  public status=-1;
  public folio:string ='0';
  public modificacionReporte=false;
  public formularioPersona:any={}
  public formularioVehiculo:any={}
  public formularioUbicacion:any={}
  public formularioEvidencias:any={}
  public formularioAccidentes:any={}
  public mostrarAyuda:boolean=false

}
