import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Platform } from '@ionic/angular';
@Directive({
    selector: '[mask]'
})
export class MaskDirective {
     valor:string = '';
    @Input() mask: string;
    constructor(
        private control: NgControl,
        private platform: Platform
    ) {
    }


    /*when loading dynamically data to the input, without this 
    the mask will only work on keyup event changes */
    @HostListener('change') ngOnChanges() {
        let value = this.control.control.value;
        if (value) {
            this.control.control.setValue(this.format(value));
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp($event: any) {
        let value
        if ($event.keyCode !== 13 && $event.keyCode !== 9) {
            if (this.platform.is('cordova')) {
                value=this.valor;
                switch ($event.keyCode) {
                    case 48: value ? value += '0' : value = '0'; break;
                    case 49: value ? value += '1' : value = '1'; break;
                    case 50: value ? value += '2' : value = '2'; break;
                    case 51: value ? value += '3' : value = '3'; break;
                    case 52: value ? value += '4' : value = '4'; break;
                    case 53: value ? value += '5' : value = '5'; break;
                    case 54: value ? value += '6' : value = '6'; break;
                    case 55: value ? value += '7' : value = '7'; break;
                    case 56: value ? value += '8' : value = '8'; break;
                    case 57: value ? value += '9' : value = '9'; break;
                    case 8: value ? value = value.substring(0, (value.length - 1)) : value; break;
                }
            } else {
                value = this.control.control.value;
            }
            this.control.control.setValue(this.format(value));
            this.valor=value;
        }

    }

    private format(v: string): string {
        let s: string = '';
        const matches = v.match(/[a-zA-Z0-9]+/g);
        if (matches !== null) {
            let value = matches.join('').split('');

            const chars = this.mask.split('');
            for (let c of chars) {
                if (value.length === 0) {
                    break;
                }

                switch (c) {
                    case '#':
                        s += value[0];
                        value = value.slice(1);
                        break;

                    case '9':
                        if (value[0].match(/\d/) !== null) {
                            s += value[0];
                            value = value.slice(1);
                        }
                        break;

                    case 'A':
                        if (value[0].match(/[a-zA-Z]/) !== null) {
                            s += value[0];
                            value = value.slice(1);
                        }
                        break;

                    default:
                        s += c;
                }
            }
        }

        return s;
    }

}