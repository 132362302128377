import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '../config';

@Injectable({
  providedIn: 'root'
})
export class CatPlazaCobroService {

  constructor(private http:HttpClient) { }

  getPlazas (){
      return this.http.get(`${CONFIG.API}/api/ase-plaza-cobro/`);
  }


}
