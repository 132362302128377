import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { AccidenteService } from 'src/app/services/accidente.service';

@Component({
  selector: 'app-registrar-accidente',
  templateUrl: './registrar-accidente.page.html',
  styleUrls: ['./registrar-accidente.page.scss'],
})
export class RegistrarAccidentePage implements OnInit {

  formulario: any = {}
  tipo_accidentes: any = []
  constructor(private modalCtrl: ModalController, private loadingController: LoadingController,
    private accidenteService: AccidenteService, private alertController: AlertController) {
    this.cargarDatos()
  }

  ngOnInit() {
  }

  cargarDatos() {
    this.accidenteService.obtenerTipoAccidentes()
      .then((accidentes) => {
        this.tipo_accidentes = accidentes;
      })
  }
  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  async guardar() {
    let loading = await this.loadingController.create({
      spinner: 'lines',
      message: 'Guardando información'
    })
    await loading.present();
    this.accidenteService.guardarAccidente(this.formulario)
      .then((data) => {
        this.mensajes('Guardado', '', 'La información se ha ingresado con éxito')
        loading.dismiss();
        this.modalCtrl.dismiss({
          'accidente': data
        });
      })
      .catch((err) => {
        this.mensajes('Algo salió mal', '', 'Intenta nuevamente más tarde')
        loading.dismiss();
      })
  }


  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
