import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CONFIG from '../config';

@Injectable({
  providedIn: 'root'
})
export class CatCarrilService {

  constructor(private http: HttpClient) { }

  getCarriles() {
    return this.http.get(`${CONFIG.API}/api/ase-carril/`);
  }

  getCarrilesByAutopista(id:number) {
    return this.http.get(`${CONFIG.API}/api/ase-carril/by-autopista/${id}`);
  }

  //Recuperar la info de los carriles
  obtenerCarriles() {
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + '/api/ase-carril/carriles').subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  guardarCarril(datos) {
    return new Promise((resolve, reject) => {
      this.http.post(CONFIG.API + '/api/ase-carril/', datos).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }
  

  update(form){
    return this.http.post(`${CONFIG.API}/api/ase-carril/actualizar`, form)
  }
  delete(form){
    return this.http.post(`${CONFIG.API}/api/ase-carril/eliminar`, form)
  }

}
