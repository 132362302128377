import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/File/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
//import aseguradora crud
import { RegistrarAseguradoraPageModule } from '../app/pages/aseguradora/registrar-aseguradora/registrar-aseguradora.module';
import { RegistrarCarrilesPageModule } from './pages/aseguradora/registrar-carriles/registrar-carriles.module';
import { ModificarCarrilesPageModule } from './pages/aseguradora/modificar-carriles/modificar-carriles.module';
import { ModificarAseguradoraPageModule } from './pages/aseguradora/modificar-aseguradora/modificar-aseguradora.module';
import { RegistrarUsuarioPageModule } from '../app/pages/cae/registrar-usuario/registrar-usuario.module'
import { ModificarUsuarioPageModule } from '../app/pages/cae/modificar-usuario/modificar-usuario.module'
import { VistaRecorridoPageModule } from '../app/pages/cae/vista-recorrido/vista-recorrido.module';
import { InspeccionResidentePageModule} from '../app/pages/residente/inspeccion-residente/inspeccion-residente.module'
import { FormularioServiciosPageModule } from '../app/pages/formulario-servicios/formulario-servicios.module'
import { VisualizarImagenPageModule } from '../app/pages/visualizar-imagen/visualizar-imagen.module'
import { VisualizarImagenObacPageModule } from '../app/pages/operacion/visualizar-imagen-obac/visualizar-imagen-obac.module'
import {FichaAdicionalOGPPageModule} from '../app/pages/ficha-adicional-ogp/ficha-adicional-ogp.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import CONFIG from './config';

import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import { HttpConfigInterceptor } from './auth.interceptor';
import { HTTP } from '@ionic-native/http/ngx';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io'
const config: SocketIoConfig = { url: CONFIG.API, options: {} }
import { FCM } from '@ionic-native/fcm/ngx';
import { Network } from '@ionic-native/network/ngx';
import { DirectivesModule } from './DirectivesModule'
import { PopoverOfflinePageModule } from './popover-offline/popover-offline.module';
import { PopoverOfflineIncidenciasMrPageModule } from './pages/popover-offline-incidencias-mr/popover-offline-incidencias-mr.module';
import { DetalleInventarioPageModule } from '../app/pages/detalle-inventario/detalle-inventario.module';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { RegistrarAccidentePageModule } from './pages/cae/registrar-accidente/registrar-accidente.module';
import { ModificarAccidentePageModule } from './pages/cae/modificar-accidente/modificar-accidente.module';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { ChatReportePageModule } from './pages/chat-reporte/chat-reporte.module';
import { NuevosCambiosPageModule } from './pages/nuevos-cambios/nuevos-cambios.module';
import { AgregarRecorridoPageModule } from './pages/cae/agregar-recorrido/agregar-recorrido.module';
import { EditarInventarioPageModule } from './pages/editar-inventario/editar-inventario.module';
import { AgregarInventarioPageModule } from './pages/agregar-inventario/agregar-inventario.module';
import { PopoverRecorridoComponent } from './pages/cae/popover-recorrido/popover-recorrido.component';
import { FotosInventarioPageModule } from './pages/fotos-inventario/fotos-inventario.module';
import { FirmaCalificacionPageModule } from './pages/firma-calificacion/firma-calificacion.module';
import { VisualizarPdfPageModule } from './pages/visualizar-pdf/visualizar-pdf.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileOpener } from '@ionic-native/file-opener/ngx';
@NgModule({
  declarations: [AppComponent, PopoverRecorridoComponent],
  entryComponents: [PopoverRecorridoComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, PdfViewerModule,
    HttpClientModule, RegistrarUsuarioPageModule, RegistrarAseguradoraPageModule, ModificarAseguradoraPageModule, RegistrarCarrilesPageModule, ModificarCarrilesPageModule ,  VisualizarImagenPageModule,VisualizarImagenObacPageModule,
    FormularioServiciosPageModule, DetalleInventarioPageModule, ModificarAccidentePageModule,
    RegistrarAccidentePageModule, VistaRecorridoPageModule, NuevosCambiosPageModule,AgregarRecorridoPageModule,
     ModificarUsuarioPageModule, DirectivesModule, PopoverOfflinePageModule,EditarInventarioPageModule, 
     ChatReportePageModule,FichaAdicionalOGPPageModule,AgregarInventarioPageModule,FotosInventarioPageModule,FirmaCalificacionPageModule,
    PopoverOfflineIncidenciasMrPageModule, VisualizarPdfPageModule],
  providers: [
    StatusBar,
    SplashScreen,
    AuthGuardService,
    NativeStorage,
    AuthenticationService,
    AppUpdate,
    SQLite,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    FilePath,
    File,
    FileTransfer,
    FileOpener,
    FCM,
    Vibration,
    Network,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
