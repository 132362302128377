import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { InventarioService } from 'src/app/services/inventario.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Component({
  selector: 'app-agregar-inventario',
  templateUrl: './agregar-inventario.page.html',
  styleUrls: ['./agregar-inventario.page.scss'],
})

export class AgregarInventarioPage implements OnInit {

  formulario: any = {
    ubicacion: {},
    detalle: {}
  }
  keys: any = [];
  datosForaneos: any = [];
  usuario: any = {}
  constructor(private loadingController: LoadingController,
    private inventarioService: InventarioService, private modalCtrl: ModalController,
    private alertController: AlertController, private geolocation: Geolocation) { }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async getLocation() {

    const loading = await this.loadingController.create({
      message: 'Obteniendo ubicacion.......',
      spinner: 'lines'
    });
    await loading.present();
    this.geolocation.getCurrentPosition().then((resp) => {
      loading.dismiss();
      this.formulario.ubicacion.latitude = resp.coords.latitude;
      this.formulario.ubicacion.longitud = resp.coords.longitude;
    }).catch((error) => {
      loading.dismiss();
      this.mensajes("Ha ocurrido un error", "", "No se pudo obtener la ubicación intenta nuevamente ")
    });

  }

  changeForm(e) {
    let tipo_inventario = e.target.value;
    switch (tipo_inventario) {
      case 'amortiguadores_impacto':
        this.keys = ['estado_general', 'observaciones', 'dimension_altura'];
        break;
      case 'area_plaza_cobro':
        this.keys = ['nombre', 'observaciones'];
        this.inventarioService.busquedaXTipo('plazas')
          .then((data) => {
            this.datosForaneos = data;
          })
        break;
      case 'banos':
        this.keys = ['nombre', 'nombre_plaza', 'wc', 'asientos_wc', 'mingitorios', 'puertas_wc', 'mesa_panales', 'limpieza_interior_banos',
          'botes_basura_gabinetes', 'suministro_papel_sanitarios', 'limpieza_vestibulos_acceso', 'lavavo_multiple', 'grifos_agua', 'jaboneras',
          'botes_basura', 'seca_manos', 'deposito_gel_antibacterial', 'espejos_lavabo', 'wc_capacidades_diferentes', 'lavabo_capacidades_diferentes',
          'accesorios_capacidades_diferentes', 'jabonera_capacidades_diferentes', 'iluminacion_exterior'];
        this.inventarioService.busquedaXTipo('plazas')
          .then((data) => {
            this.datosForaneos = data;
          })
        break;
      case 'barreras_concreto':
        this.keys = ['nombre', 'longitud_mts', 'tipo_elemento', 'estado_general', 'observaciones'];
        break;
      case 'bienes_plaza_cobro':
        this.keys = ['nombre', 'cantidad', 'medida', 'observaciones', 'area_localiza'];
        this.inventarioService.busquedaXTipo('areas')
          .then((data) => {
            this.datosForaneos = data;
          })
          .catch((err) => {
            console.log(err)
          })
        break;
      case 'bienes_y_equipos':
        this.keys = ['nombre', 'elemento_inventariado', 'marca', 'modelo',
          'numero_serie', 'descripcion', 'cantidad', 'medida', 'estado_general', 'observaciones'];
        break;
      case 'carril':
        this.keys = ['nombre', 'identificador_carril_local', 'numero_carril_nacional',
          'tipo_carril', 'comparte_iseta_carril', 'extension_telefonica', 'observaciones'];
        this.inventarioService.busquedaXTipo('plazas')
          .then((data) => {
            this.datosForaneos = data;
          })
          .catch((err) => {
            console.log(err)
          })
        break;
      case 'cercado_derecho_via':
        this.keys = ['nombre', 'segmento', 'tipo', 'malla_borreguera_m',
          'alambre_puas_m', 'separacion_postes_m', 'observaciones'];
        break;
      case 'componente_ITS':
        this.keys = ['nombre', 'marca', 'modelo',
          'numero_serie', 'estado_general', 'observaciones'];
        this.inventarioService.busquedaXTipo('elemento')
          .then((data) => {
            this.datosForaneos = data;
          })
          .catch((err) => {
            console.log(err)
          })
        break;
      case 'defensas_metalicas':
        this.keys = ['longitud', 'altura', 'tipo_defensa', 'estado_general', 'observaciones'];
        break;
      case 'drenaje_longitudinal':
        this.keys = ['nombre', 'tipo_drenaje', 'posicion', 'x', 'y', 'z', 'porcentaje_azolve', 'revestida',
          'desagua_en', 'estado_desague', 'observaciones'];
        break;
      case 'drenaje_transversal':
        this.keys = ['nombre', 'tipo_drenaje', 'estado_general', 'porcentaje_azolve', 'longitud', 'esviaje_grados', 'diametro_tubo', 'material',
          'alabeado', 'limpieza_entrada', 'limpieza_salida', 'estado_boquilla_entrada', 'estado_boquilla_salida', 'fisuras_en_hastiales', 'fisuras_en_clave','fisuras_en_aletas', 'observaciones'];
        break;
      case 'elemento_its':
        this.keys = ['nombre', 'tipo', 'observaciones'];
        break;
      case 'equipo_informatico_plaza_cobro':
        this.keys = ['nombre', 'marca', 'modelo',
          'numero_serie', 'cantidad', 'medida', 'area_localiza', 'tipo_equipo', 'estado_general'];
        this.inventarioService.busquedaXTipo('carril')
          .then((data) => {
            this.datosForaneos = data;
          })
          .catch((err) => {
            console.log(err)
          })
        break;
      case 'obras_bordillos':
        this.keys = ['dimension_a', 'dimension_b', 'dimension_c', 'observaciones'];
        break;
      case 'obras_canales':
        this.keys = ['longitud', 'tipo_revestimiento', 'estado_general', 'observaciones'];
        break;
      case 'obras_cunetas':
        this.keys = ['dimension_a', 'dimension_b', 'longitud', 'tipo_revestimiento', 'estado_general', 'observaciones'];
        break;
      case 'obras_contracunetas':
        this.keys = ['dimension_a', 'dimension_b', 'dimension_c', 'dimension_d', 'longitud',
          'tipo_revestimiento', 'estado_general', 'observaciones'];
        break;
      case 'obras_drenaje':
        this.keys = ['material', 'ancho', 'altura', 'diametro', 'longitud', 'estado_general', 'observaciones'];
        break;
      case 'obras_estructuras':
        this.keys = ['estado_general', 'observaciones', 'nombre'];
        break;
      case 'obras_guarnicion':
        this.keys = ['dimension_b_mayor', 'dimension_b_menor', 'dimension_altura', 'estado_general', 'observaciones'];
        break;
      case 'obras_lavaderos':
        this.keys = ['estado_general', 'observaciones', 'dimension_largo', 'dimension_ancho'];
        break;
      case 'plaza_cobro':
        this.keys = ['nombre', 'eje_carretero', 'numero_nacional_plaza', 'nombre_plaza', 'nombre_administrador', 'servicio_medico', 'servicio_gruas_gratuito',
          'atencion_residente', 'numero_tel_plaza', 'cant_carriles_manuales_sencillos', 'cant_carriles_multimodales', 'cant_carriles_exclusivo_telepeaje', 'cant_carriles_tandem',
          'cant_carriles_telepeaje', 'cant_carriles_interoperables', 'cant_carriles_dimensionados', 'cant_carriles_manual_reversibles', 'observaciones'];
        break;
      case 'senalamiento_vertical':
        this.keys = ['tipo', 'descripcion', 'posicion', 'observaciones'];
        break;
      case 'senalamiento_vertical_indicadores':
        this.keys = ['nombre', 'lado_derecho', 'lado_izquierdo', 'segmento', 'estado_general', 'total_por_km'];
        break;
      case 'senalamiento_horizontal':
        this.keys = ['clave', 'segmento', 'descripcion', 'color', 'dimension', 'ubicacion'];
        break;
      case 'senalamiento_horizontal_boyas':
        this.keys = ['elementos_pza', 'tipo', 'observaciones'];
        break;
      case 'senalamiento_horizontal_flechas':
        this.keys = ['color', 'longitud_mts', 'elementos_pza', 'ancho_m', 'area_m2', 'observaciones'];
        break;
      case 'taludes_cortes':
        this.keys = ['longitud', 'estado_general', 'observaciones'];
        break;
      case 'tienda_convivencia':
        this.keys = ['nombre', 'nombre_plaza', 'limpieza_general_tienda', 'atencion_observada', 'buzon_quejas_sugerencias', 'rampa_capacidades_diferentes',
          'iluminacion_exterior', 'jardin_instalacion', 'telefono_publico', 'contenedores_basura', 'estacionamientos', 'limpieza_estacionamiento', 'mapa_interior'
        ];
        this.inventarioService.busquedaXTipo('plazas')
          .then((data) => {
            this.datosForaneos = data;
          })
          .catch((err) => {
            console.log(err)
          })
        break;

        case 'reporte_inventario' :
          this.keys = ['nombre', 'area', 'marca', 'modelo' , 'numero_serie', 'observaciones'];
        break;

    }

  }

  async guardar() {
    if (Object.keys(this.formulario.detalle).length > 1) {
      const loading = await this.loadingController.create({
        message: 'Guardando datos, un momento por favor...',
        spinner: 'lines'
      });
      await loading.present();

      this.inventarioService.guardarInventario(this.formulario)
        .then((data: any) => {
          loading.dismiss();
          this.mensajes('Operación completa', '', data.msg);
          this.modalCtrl.dismiss({
            'dismissed': true
          });
        })
        .catch((err) => {
          loading.dismiss();
        })
    } else {
      this.mensajes('Selecciona el tipo de inventario', '', 'E ingresa sus datos correspondientes')
    }
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });
    await alert.present();

  }
}
