import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';
@Injectable({
  providedIn: 'root'
})
export class ChatReporteService {

  constructor(public http:HttpClient,public platform:Platform, public httpNative:HTTP) { }

  obtenerMensajes(id) {
    return new Promise((resolve, reject) => {
      if (this.platform.is('cordova')) {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/mensajes/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      } else {
        this.http.get(CONFIG.API + '/api/mensajes/'+id)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      }
    });
  }

  guardarMensaje(datos) {
    return new Promise((resolve, reject) => {
      if (this.platform.is('cordova')) {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/mensajes/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      } else {
        this.http.post(CONFIG.API + '/api/mensajes/', datos)
          .subscribe((accidente: any) => {
            resolve(accidente)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      }
    });
  }
}
