import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx'
import CONFIG from '../config'
import { Platform } from '@ionic/angular';
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';

@Injectable({
  providedIn: 'root'
})
export class AseguradoraService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  obtenerAseguradoras(tipo = 0) {
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + `/api/ase-aseguradora?tipo=${tipo}`).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  getAseguradorasActivas(fecha) {
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + `/api/ase-aseguradora/get/activas?fecha=${fecha}`).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  getAseguradorasActivasVariacion(fecha) {
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + `/api/ase-aseguradora/get/variacion/activas?fecha=${fecha}`).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  guardarAseguradora(datos) {
    return new Promise((resolve, reject) => {
      this.http.post(CONFIG.API + '/api/ase-aseguradora/', datos).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  obtenerAseguradoraId(id) {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/ase-aseguradora/' + id).subscribe(
          result=>{
            resolve(result);
          },err=>{
            reject(err);
          }
        )
      })
  }

  modificarAseguradora(datos, id) {
    return new Promise((resolve, reject) => {
      this.http.put(CONFIG.API + '/api/ase-aseguradora/' + id, datos).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  eliminarAseguradora(id) {

    return new Promise((resolve, reject) => {
      this.http.delete(CONFIG.API + '/api/ase-aseguradora/' + id).subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }

  obtenerTipoAseguradoras(){
    return new Promise((resolve, reject) => {
      this.http.get(CONFIG.API + '/api/ase-aseguradora/tipos').subscribe(
        result=>{
          resolve(result);
        },err=>{
          reject(err);
        }
      )
    })
  }
}
