import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { UsuarioService } from '../../../services/usuario.service'
@Component({
  selector: 'app-registrar-usuario',
  templateUrl: './registrar-usuario.page.html',
  styleUrls: ['./registrar-usuario.page.scss'],
})
export class RegistrarUsuarioPage implements OnInit {

  usuario:any={}
  public formulario: FormGroup;
  constructor(private usuarioService: UsuarioService, public loadingController: LoadingController, public alertController: AlertController, public modalCtrl: ModalController, public formBuilder: FormBuilder, ) {
    this.usuario=JSON.parse(window.localStorage.getItem('usuario'))
    this.crearFormulario();
  }

  ngOnInit() {
  }


  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  crearFormulario() {
    this.formulario = this.formBuilder.group({
      nombres: ['', Validators.required],
      usuario: ['', Validators.required],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required],
      rol: ['', Validators.required]
    });
  }

  async guardar() {
    if (this.formulario.value.password == this.formulario.value.passwordRepeat) {
      const loading = await this.loadingController.create({
        message: 'Guardando usuario.....',
        duration: 15000
      });
      await loading.present();
      this.usuarioService.guardarUsuario(this.formulario.value)
        .then((data:any) => {
          if(data.error){
            this.mensajes("El usuario ya existe", "", "Intente nuevamente con un usuario diferente")
          }else if(data.ok){
            this.mensajes("Usuario guardado", "", "El usuario se ha registrado con éxito")
            this.modalCtrl.dismiss({
              'usuario': data
            });
          }
          loading.dismiss()
        })
        .catch((err)=>{
          console.log(err)
        })
    } else {
      this.mensajes("Error al guardar", "Las contraseñas no coinciden", "Verificalas e intenta nuevamente")
    }
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
