import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { CatCarrilService } from 'src/app/services/cat-carril.service';
import { NavParams } from '@ionic/angular';
//Uso de DataTable
import { SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-modificar-carriles',
  templateUrl: './modificar-carriles.page.html',
  styleUrls: ['./modificar-carriles.page.scss'],
})
export class ModificarCarrilesPage implements OnInit {
  //Tabla
  SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  rows: any = [];
  selected = [];
  editing = {};
  temp: any = [];
  //Datos
  carriles: any = [];
  nombrePlaza: String;
  constructor(public alertController: AlertController, public loadingController: LoadingController, public navParams: NavParams, public aseCarrilesService: CatCarrilService, public modalCtrl: ModalController) {
    this.nombrePlaza =  this.navParams.get('nombre');
  }

  ngOnInit() {
    this.cargarDatos();
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  public cargarDatos() {
    this.aseCarrilesService.getCarrilesByAutopista(this.navParams.get('id')).subscribe(
      (carriles: any) => {
        let {id , nombre} = carriles;
        this.carriles = carriles;
        this.rows = this.carriles;
        this.temp = this.rows;
      }, err => {
        console.log(err);
      }
    )
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  onSelect({ selected }) {
  }

  async updateValue(event, cell, id, index) {
    index = this.rows.findIndex(row => row.id == id);
    let loading = await this.loadingController.create({
      message: 'Actualizando información...',
      spinner: 'lines'
    })
    loading.present();
    var form: any = {};
    form.valor = event.target.value; //nuevo valor
    form.campo = cell; //campo nombre
    form.id = id; //id carril
    

    this.aseCarrilesService.update(form).subscribe(
      (data) => {
        loading.dismiss();
        this.editing[id + '-' + cell] = false;
        this.rows[index][cell] = form.valor
        this.cargarDatos();

      }, err => {
        loading.dismiss();
        this.mensajes("Algo no salió bien", "", "Intenta nuevamente más tarde")
      })
  }


  async eliminar(id) {
    const alert = await this.alertController.create({
      header: '¿Estás seguro de eliminar el carril?',
      message: 'El carril será eliminado ',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Eliminar',
          handler: async () => {
            const loading = await this.loadingController.create({
              message: 'Eliminando...',
              spinner: 'lines'
            });
            await loading.present();
            var form: any = {}
            form.id = id
            this.aseCarrilesService.delete(form).subscribe(
              result => {
                loading.dismiss();
                this.cargarDatos();
              },
              err => {
                loading.dismiss();
                this.mensajes('Algo salió mal', '', 'Inténtalo nuevamente más tarde');
              }
            )
          }
        }
      ]
    });
    await alert.present();
  }

}
