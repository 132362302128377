import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { ReporteAmbulanciaService } from '../../services/reporte-ambulancia.service';
import { ReporteGruaService } from '../../services/reporte-grua.service';
import { ServiciosService } from '../../services/servicios.service';
import { EvidenciaService } from '../../services/evidencia.service'
import { ReportePSAVVService } from '../../services/reporte-psavv.service';
import { InformacionCompartidaService } from '../psavv/informacion-compartida.service';

@Component({
  selector: 'app-formulario-servicios',
  templateUrl: './formulario-servicios.page.html',
  styleUrls: ['./formulario-servicios.page.scss'],
})
export class FormularioServiciosPage implements OnInit {
  uploadedFiles: any = []
  formulario: any = {}
  usuario: any = {}
  constructor(public evidenciaService: EvidenciaService, public serviciosService: ServiciosService,
    public alertController: AlertController, public loadingController: LoadingController,
    public reporteGruaService: ReporteGruaService, public reporteAmbulanciaService: ReporteAmbulanciaService,
    public modalCtrl: ModalController, public navParams: NavParams, public platform: Platform,
    public reportePSAVVService: ReportePSAVVService, public informacionCompartida: InformacionCompartidaService) {
    this.formulario.servicio = this.navParams.get('servicio')
    this.formulario.nombre = this.navParams.get('servicio')
    this.formulario.idReporte = this.navParams.get('idReporte')
    this.usuario = JSON.parse(window.localStorage.getItem('usuario'));
    this.obtenerDatos();
  }

  ngOnInit() {
  }
  fileChange(element) {
    this.uploadedFiles = element.target.files;
  }

  public obtenerDatos() {
    var idServicio = this.navParams.get('idServicio');
    if (idServicio) {
      switch (this.formulario.servicio) {
        case 'Ambulancia':
          this.reporteAmbulanciaService.obtenerReporteAmbulancia(idServicio)
            .then((data: any) => {
              this.formulario.horaNot = data.hora_not;
              this.formulario.horaTranslado = data.hora_translado;
              this.formulario.horaEntrega = data.hora_entrega;
              this.formulario.horaLlegada = data.hora_llegada;
              this.formulario.ocupantes = data.ocupantes;
              this.formulario.heridos = data.heridos;
              this.formulario.decesos = data.decesos;
              this.formulario.unidad = data.unidad_llega;
              this.formulario.horaFinal = data.hora_finalizacion;
              this.formulario.observaciones = data.observaciones == 'null' ? '' : data.observaciones;;
              this.formulario.evidencias = data.evidencias;
              this.formulario.traslado_a = data.traslado_a;
              this.formulario.inicioRecorrido=data.hora_inicio_recorrido;
              this.formulario.finRecorrido=data.hora_fin_recorrido;
              this.formulario.turno=data.turno;
              this.formulario.folio=data.folio;

              this.reporteAmbulanciaService.obtenerPersonasIncidencia(idServicio)
                .then((data: any) => {
                  //Información herido Femenino
                  var index = data.findIndex(pi => (pi.tipo == 0 && pi.sexo == "F"));
                  if (index != -1) {
                    this.formulario.numHeridosF = data[index].numero;
                    this.formulario.edadesHeridosF = data[index].edades
                  }

                  //Información herido Masculino
                  var index = data.findIndex(pi => (pi.tipo == 0 && pi.sexo == "M"));
                  if (index != -1) {
                    this.formulario.numHeridosM = data[index].numero;
                    this.formulario.edadesHeridosM = data[index].edades
                  }

                  //Información deceso Masculino
                  var index = data.findIndex(pi => (pi.tipo == 1 && pi.sexo == "M"));
                  if (index != -1) {
                    this.formulario.numDecesosM = data[index].numero;
                    this.formulario.edadesDecesosM = data[index].edades
                  }

                  //Información deceso Femenino
                  var index = data.findIndex(pi => (pi.tipo == 1 && pi.sexo == "F"));
                  if (index != -1) {
                    this.formulario.numDecesosF = data[index].numero;
                    this.formulario.edadesDecesosF = data[index].edades
                  }
                })
            })
          break;
        case 'Grúa':
          this.reporteGruaService.obtenerReporteGrua(idServicio)
            .then((data: any) => {
              this.formulario.horaNot = data.hora_not;
              this.formulario.horaLlegada = data.hora_llegada;
              this.formulario.destino = data.destino_grua;
              this.formulario.unidad = data.unidad_llega;
              this.formulario.horaFinal = data.hora_finalizacion;
              this.formulario.observaciones = data.observaciones == 'null' ? '' : data.observaciones;
              this.formulario.evidencias = data.evidencias;
              this.formulario.inicioRecorrido=data.hora_inicio_recorrido;
              this.formulario.finRecorrido=data.hora_fin_recorrido;
              this.formulario.turno=data.turno;
              this.formulario.folio=data.folio;
            })
          break;
        case 'PSAVV':
          this.reportePSAVVService.obtenerReportePSAVV(idServicio)
            .then((data: any) => {
              this.formulario.folio=data.folio;
              this.formulario.horaNot = data.hora_not;
              this.formulario.horaSalida = data.hora_salida;
              this.formulario.horaSenalizacion = data.hora_senalizacion;
              this.formulario.horaLlegada = data.hora_llegada;
              this.formulario.horaRestitucion = data.hora_restitucion;
              this.formulario.unidad = data.unidad_llega;
              this.formulario.horaFinal = data.hora_finalizacion;
              this.formulario.observaciones = data.observaciones == 'null' ? '' : data.observaciones;
              this.formulario.evidencias = data.evidencias;
              const str = data.procedimientoRes
              //Si viene el procedimiento
              if (str) {
                const words = str.split('/-');
                this.formulario.paso1 = !words[0] ? '' : words[0];
                this.formulario.paso2 = !words[1] ? '' : words[1];
                this.formulario.paso3 = !words[2] ? '' : words[2];
              }

              //Para las horas del seguro y 911, se recuperan de los servicios si existen.

              this.serviciosService.recuperarHorasServicioyEmergencia(data.id_reporte_general)
              .then((data:any)=>{
                const indexEmergencia= data.findIndex(servicio=>servicio.nom_servicio==='Policia federal');
                const indexSeguro= data.findIndex(servicio=>servicio.nom_servicio==='Ajustador seguro');

                this.formulario.emergencia = indexEmergencia != -1 ? data[indexEmergencia].hora_not : null;
                this.formulario.seguro = indexSeguro != -1 ? data[indexSeguro].hora_not : null;
              })
              

            })
          break;
        default:
          this.serviciosService.obtenerServicio(idServicio)
            .then((data: any) => {
              this.formulario.horaNot = data.hora_not;
              this.formulario.horaLlegada = data.hora_llegada;
              this.formulario.cierreLlamada = data.cierreLlamada;
              this.formulario.unidad = data.unidad_llega;
              this.formulario.horaFinal = data.hora_finalizacion;
              this.formulario.observaciones = data.observaciones == 'null' ? '' : data.observaciones;
            })
          break;

      }
    }
  }

  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async guardar() {
    const loading = await this.loadingController.create({
      message: 'Guardando...',
      duration: 10000,
      spinner: 'lines'
    });
    await loading.present();

    var idServicio = this.navParams.get('idServicio');
    //Si el servicio ya esta creado se actualiza
    if (idServicio) {
      switch (this.formulario.servicio) {
        case 'Ambulancia':
          //Validar el numero de heridos
          if (this.validarInformacionHeridos()) {
            this.setFormHeridosDecesos()
            if (this.platform.is('cordova')) {  //Validar la plataforma, si es Android sólo modifica no guarda las imágenes
              this.reporteAmbulanciaService.modificarReporte(this.formulario, idServicio)
                .then((data) => {
                  loading.dismiss()
                  this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                  this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
                })
            } else {
              this.reporteAmbulanciaService.modificarReporte(this.formulario, idServicio)
                .then((data) => {
                  this.formData(2, this.formulario.evidencias)
                    .then((formData: any) => {
                      this.evidenciaService.imagesMultiples(formData)
                        .then((form) => {
                          loading.dismiss()
                          this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                          this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
                        })
                    })
                    .catch((err) => {
                      loading.dismiss()
                      this.mensajes('Algo salió mal', err.err, '')
                    })
                })
            }
          } else {
            loading.dismiss()
            this.mensajes("Completa la información", "", "No concuerda el número de heridos o decesos ingresados")
          }
          break;
        case 'Grúa':
          if (this.platform.is('cordova')) {  //Validar la plataforma, si es Android sólo modifica no guarda las imágenes
            this.reporteGruaService.modificarReporte(this.formulario, idServicio)
              .then((data) => {
                loading.dismiss()
                this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
              })
          } else {
            this.formData(3, this.formulario.evidencias)
              .then((formData: any) => {
                console.log(formData.get('files'))
                this.reporteGruaService.modificarReporte(this.formulario, idServicio)
                  .then((data) => {
                    this.evidenciaService.imagesMultiples(formData)
                      .then((form) => {
                        loading.dismiss()
                        this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                        this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
                      })
                  })
              })
              .catch((err) => {
                loading.dismiss()
                this.mensajes('Algo salió mal', err.err, '')
              })
          }

          break;
        case 'PSAVV':
          if (this.platform.is('cordova')) {  //Validar la plataforma, si es Android sólo modifica no guarda las imágenes
            this.reportePSAVVService.modificarReporte(this.formulario, idServicio)
              .then((data) => {
                loading.dismiss()
                this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
              })
          } else {
            this.formData(1, this.formulario.evidencias)
              .then((formData: any) => {
                this.reportePSAVVService.modificarReporte(this.formulario, idServicio)
                  .then((data) => {
                    this.evidenciaService.imagesMultiples(formData)
                      .then((form) => {
                        loading.dismiss()
                        this.mensajes("Se ha actualizado correctamente", "", "Se ha actualizado el reporte");
                        this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
                      })
                  })
              })
              .catch((err) => {
                loading.dismiss()
                this.mensajes('Algo salió mal', err.err, '')
              })
          }
          break;
        default:
          this.serviciosService.modificarServicio(this.formulario, idServicio)
            .then((servicio) => {
              loading.dismiss()
              this.mensajes("Se ha guardado correctamente", "", "Se han registrado las horas");
              this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
            })

      }


    } else {
      //Si no está creado se guarda
      switch (this.formulario.servicio) {
        case 'Ambulancia':
          if (this.validarInformacionHeridos()) {
            this.formulario.nombre = "Ambulancia";
            this.reporteAmbulanciaService.guardarReporteAmbulancia(this.formulario)
              .then((ambulancia) => {
                loading.dismiss()
                this.mensajes("Se ha guardado correctamente", "", "Se han registrado las horas");
                this.modalCtrl.dismiss(
                  { servicio: this.formulario.servicio }
                );
              })
          } else {
            loading.dismiss()
            this.mensajes("Completa la información", "", "No concuerda el número de heridos o decesos ingresados")
          }
          break;
        case 'Grúa': this.formulario.nombre = "Grúa";
          this.reporteGruaService.guardarReporteGrua(this.formulario)

            .then((grua) => {
              loading.dismiss()
              this.mensajes("Se ha guardado correctamente", "", "Se han registrado las horas");
              this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
            })
          break;
        default:
          this.serviciosService.guardarServicio(this.formulario)
            .then((servicio) => {
              loading.dismiss()
              this.mensajes("Se ha guardado correctamente", "", "Se han registrado las horas");
              this.modalCtrl.dismiss({ servicio: this.formulario.servicio });
            })

      }

    }
  }

  setFormHeridosDecesos() {
    if (this.formulario.heridos == 0) {
      this.formulario.numHeridosF = 0;
      this.formulario.numHeridosM = 0;
      this.formulario.edadesHeridosM = null;
      this.formulario.edadesHeridosF = null;
    }

    if (this.formulario.decesos == 0) {
      this.formulario.numDecesosM = 0;
      this.formulario.numDecesosF = 0;
      this.formulario.edadesDecesosM = null;
      this.formulario.edadesDecesosF = null;
    }
  }
  validarInformacionHeridos(): boolean {
    let bandera = false;
    //Validar información de los heridos
    if (this.formulario.heridos && !this.formulario.decesos) {
      if (this.formulario.heridos == ((this.formulario.numHeridosM ? this.formulario.numHeridosM : 0) + (this.formulario.numHeridosF ? this.formulario.numHeridosF : 0))) {
        bandera = true;
      }
    } else if (this.formulario.heridos && this.formulario.decesos) {
      if (this.formulario.heridos == ((this.formulario.numHeridosM ? this.formulario.numHeridosM : 0) + (this.formulario.numHeridosF ? this.formulario.numHeridosF : 0))
        && this.formulario.decesos == ((this.formulario.numDecesosM ? this.formulario.numDecesosM : 0) + (this.formulario.numDecesosF ? this.formulario.numDecesosF : 0))) {
        bandera = true;
      }
    } else if (this.formulario.decesos && !this.formulario.heridos) {
      if (this.formulario.decesos == ((this.formulario.numDecesosM ? this.formulario.numDecesosM : 0) + (this.formulario.numDecesosF ? this.formulario.numDecesosF : 0))) {
        bandera = true;
      }
    } else {
      return bandera = true
    }
    return bandera;
  }
  formData(tipo, evidencias) {
    evidencias = 5 - evidencias;
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      if (this.uploadedFiles.length == 0) {
        resolve(formData)
      } else {
        formData.append('tipo', tipo)
        formData.append('idReporte', this.formulario.idReporte)
        if (this.uploadedFiles.length < evidencias) {
          for (var i = 0; i < this.uploadedFiles.length; i++) {
            //Validar que sea un imagen
            if (this.uploadedFiles[i].type == "image/jpeg" || this.uploadedFiles[i].type == "image/png") {
              formData.append('files', this.uploadedFiles[i], this.uploadedFiles[i].name);
              if (i == this.uploadedFiles.length - 1) {
                resolve(formData)
              }
            } else {
              reject({ err: 'Alguno de los archivos no es formato jpg o png' })
            }
          }
        } else {
          reject({ err: 'Puedes seleccionar ' + (evidencias - 1) + ' imagenes como máximo' })
        }
      }
    })
  }
  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
