import { HttpHeaders } from '@angular/common/http';

export default function () {

  let token = window.localStorage.getItem('token')
  if (token) {
    return { 'Authorization':`Bearer ${token}`, 'Access-Control-Allow-Origin': '*', 'Content-Type':'application/json'}
  } else {
    return {headers:new HttpHeaders()};
  }
}