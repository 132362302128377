import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
declare var Push: any;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(public alertController: AlertController, public router: Router) {
  }

  eventoIncidencia() {
    
    return new Promise((resolve, reject) => {
      /*
      this.socket.fromEvent('incidencia')
        .subscribe((data: any) => {
          //Verificar si tiene permisos
          if (Push.Permission.has()) {
            this.mensajeIncidencia(data.id,data.folio, "cae")
          } else {
            Push.Permission.request((onGranted) => {
              //Se aceptan los permisos
              this.mensajeIncidencia(data.id,data.folio, "cae")
            },
              (onDenied) => {
                //No se aceptan
                this.mensajes("Error al notificar", "", "No se han aceptado los permisos de <strong>notificación</strong>")
              })
          }
        })
    */
   })
    
  }

  eventoIncidenciaCae() {

    return new Promise((resolve, reject) => {
      /*
      this.socket.fromEvent('incidenciaAutocontrol')
        .subscribe((data: any) => {
          //Verificar si tiene permisos
          if (Push.Permission.has()) {
            this.mensajeIncidencia(data.id,data.folio, "autocontrol")
          } else {
            Push.Permission.request((onGranted) => {
              //Se aceptan los permisos
              this.mensajeIncidencia(data.id,data.folio, "autocontrol")
            },
              (onDenied) => {
                //No se aceptan
                this.mensajes("Error al notificar", "", "No se han aceptado los permisos de <strong>notificación</strong>")
              })
          }
        })
    */

    })
  }

  eventoInspeccion(usuario) {
    return new Promise((resolve, reject) => {
      /*
      this.socket.fromEvent('inspeccion')
        .subscribe((data: any) => {
           //Verificar si tiene permisos
           if (Push.Permission.has()) {
            this.mensajeInspeccion(data.id, usuario)
          } else {
            Push.Permission.request((onGranted) => {
              //Se aceptan los permisos
              this.mensajeInspeccion(data.id, usuario)
            },
              (onDenied) => {
                //No se aceptan
                this.mensajes("Error al notificar", "", "No se han aceptado los permisos de <strong>notificación</strong>")
              })
          }
        })
        */
    })

  }
  conectar() {
    //this.socket.connect();
  }

  desconectar() {
    //this.socket.disconnect();
  }

  mensajeIncidencia(id, folio, tipo) {
    Push.create(tipo == "cae" ? "Se ha subido una nueva incidencia con el folio No. " + folio : "Incidencia con folio No. " + folio + " lista para revisión.", {
      body: "¿Deseas ver la incidencia?",
      icon: './assets/img/mro.png',
      timeout: 8000,
      onClick: () => {
        if (tipo == 'cae') {
          this.router.navigate(['menuCae/levantamiento', id])
        } else if (tipo == 'autocontrol') {
          this.router.navigate(['menuAutocontrol/levantamiento', id])
        }
      }
    });
  }

  mensajeInspeccion(id, usuario) {
    Push.create("Se ha subido una nueva inspección con el folio N° " + id, {
      body: "¿Deseas ver la incidencia?",
      icon: './assets/img/mro.png',
      timeout: 8000,
      onClick: () => {
        switch (usuario.id_tipo_usuario) {
          case 3: this.router.navigate(['/menuAutocontrol/visualizarInspeccion', id]); break;
          case 5: this.router.navigate(['/menuMR/visualizarInspeccion', id]); break;
          case 6: this.router.navigate(['/menuResidente/visualizarInspeccionR', id]); break;
        }
      }
    });
  }

  emitirIncidencia(id) {
    //this.socket.emit('nuevaIncidencia', id)
  }
  emitirIncidenciaCae(id) {
    //this.socket.emit('envioIncidencia', id)
  }

  emitirInspeccion(id) {
    //this.socket.emit('nuevaInspeccion', id)
  }

  async messagePersonalizado(idrepGen, tipo, usuario: any) {
    const alert = await this.alertController.create({
      header: usuario.id_tipo_usuario ? 'Se ha subido una nueva inspección con el folio N° ' + idrepGen : tipo == 'autocontrol' ? "Incidencia con folio No. " + idrepGen + " lista para revisión." : 'Se ha subido una nueva incidencia con el folio No. ' + idrepGen,
      message: '¿Deseas ver la inicidencia?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Ver',
          handler: () => {
            if (tipo == 'cae') {
              this.router.navigate(['menuCae/levantamiento', idrepGen])
            } else if (tipo == 'autocontrol') {
              this.router.navigate(['menuAutocontrol/levantamiento', idrepGen])
            } else if (tipo == 'inspeccion') {
              switch (usuario.id_tipo_usuario) {
                case 3: this.router.navigate(['/menuAutocontrol/visualizarInspeccion', idrepGen]); break;
                case 5: this.router.navigate(['/menuMR/visualizarInspeccion', idrepGen]); break;
                case 6: this.router.navigate(['/menuResidente/visualizarInspeccionR', idrepGen]); break;
              }
            }

          }
        }
      ]
    });

    await alert.present()
      .catch((err) => {
        console.log(err)
      })
  }

  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
}
