import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, AlertController } from '@ionic/angular';
import { AccidenteService } from 'src/app/services/accidente.service';

@Component({
  selector: 'app-modificar-accidente',
  templateUrl: './modificar-accidente.page.html',
  styleUrls: ['./modificar-accidente.page.scss'],
})
export class ModificarAccidentePage implements OnInit {

  formulario: any = {}
  tipo_accidentes: any = []
  constructor(private modalCtrl: ModalController, private navParams: NavParams,
    private accidenteService: AccidenteService, private loadingController: LoadingController,
    private alertController: AlertController) {
    this.obtenerDatos();
  }

  ngOnInit() {
  }
  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async obtenerDatos() {
    const loading = await this.loadingController.create({
      message: 'Un momento por favor, cargando datos',
      spinner: 'lines'
    })
    loading.present();
    Promise.all([this.accidenteService.obtenerTipoAccidentes(),
      this.accidenteService.buscarAccidenteXID(this.navParams.get('id'))
    ])
      .then((data: any) => {
        loading.dismiss();
        this.tipo_accidentes = data[0];
        this.formulario.tipo = data[1].id_accidente;
        this.formulario.tipo_accidente = data[1].tipo_accidente;
        this.formulario.descripcion = data[1].nombre_accidente;
      })
      .catch((err) => {
        loading.dismiss();
        this.mensajes('Algo salió mal', '', 'Verifica tu conexión a internet y vuelve a intentar')
      })


  }

  cambiarTexto(event){
    switch(event.target.value){
      case '1': this.formulario.tipo_accidente='Descomosturas'; break;
      case '2': this.formulario.tipo_accidente='Fuerza mayor'; break;
      case '3': this.formulario.tipo_accidente='Accidente'; break;
      case '4': this.formulario.tipo_accidente='Incidente'; break;

    }

  }

  async modificar() {
    let loading = await this.loadingController.create({
      spinner: 'lines',
      message: 'Actualizando información...'
    })
    await loading.present();
    this.accidenteService.modificarAccidente(this.navParams.get('id'), this.formulario)
      .then((data) => {
        this.mensajes('Actualizando', '', 'La información se ha actualizado con éxito')
        loading.dismiss();
        this.modalCtrl.dismiss({
          'accidente': data
        });
      })
      .catch((err) => {
        this.mensajes('Algo salió mal', '', 'Intenta nuevamente más tarde')
        loading.dismiss();
      })
  }


  async mensajes(header, subHeader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

}
