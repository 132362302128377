import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';

@Injectable({
  providedIn: 'root'
})
export class ReportePSAVVService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }

  modificarReporte(datos,id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.put(CONFIG.API + '/api/reportePSAVV/'+id, datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.put(CONFIG.API + '/api/reportePSAVV/'+id,datos)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  obtenerReportePSAVV(id) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/reportePSAVV/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/reportePSAVV/'+id)
          .subscribe((persona: any) => {
            resolve(persona)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}
