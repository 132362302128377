import { Component, OnInit } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-offline',
  templateUrl: './popover-offline.page.html',
  styleUrls: ['./popover-offline.page.scss'],
})
export class PopoverOfflinePage implements OnInit {

  incidencias:any=[]
  constructor(
    public nativeStorage:NativeStorage,
    public popoverController:PopoverController
  ) {
    this.recuperarDatosLocalStorage()
   }

  ngOnInit() {}

  async recuperarDatosLocalStorage(){
    this.nativeStorage.getItem('incidencia')
    .then(
      async (data)=>{
        this.incidencias=JSON.parse(data);
      },
      (err)=>{

      }

    )
  }
  seleccionarIncidencia(incidencia){
    this.popoverController.dismiss(incidencia)
  }

}
