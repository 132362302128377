import { HttpClient } from '@angular/common/http';
import CONFIG from '../config'
import { AuthenticationService } from './authentication.service'
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx'
import { from } from 'rxjs';
import enviarToken from '../iterceptorNative';import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EvidenciasMRService {

  constructor(public httpNative: HTTP, public http: HttpClient, public platform: Platform) { }
 
  obtenerEvidenciasMR(id) {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/evidenciasMR/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/evidenciasMR/'+id)
          .subscribe((reporteGen: any) => {
            resolve(reporteGen)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  obtenerEvidenciasR(id) {
    //Si la plataforma es Android se usa los métodos nativos de Ionic HTTP
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.get(CONFIG.API + '/api/evidenciasMR/residente/'+id, {}, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      //Navegadores Brower
      return new Promise((resolve, reject) => {
        this.http.get(CONFIG.API + '/api/evidenciasMR/residente/'+id)
          .subscribe((reporteGen: any) => {
            resolve(reporteGen)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }

  }
  
  guardarEvidenciasMR(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/evidenciasMR/', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/evidenciasMR/', datos)
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
  guardarEvidenciasResidente(datos) {
    if (this.platform.is('cordova')) {
      return new Promise((resolve, reject) => {
        let nativeCall = this.httpNative.post(CONFIG.API + '/api/evidenciasMR/Residenteevidencia', datos, enviarToken())
        from(nativeCall).pipe()
          .subscribe((data: any) => {
            resolve(JSON.parse(data.data))
          }, err => {
            console.log(err);
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(CONFIG.API + '/api/evidenciasMR/Residenteevidencia', datos)
          .subscribe((reporte: any) => {
            resolve(reporte)
          }, (err) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}
