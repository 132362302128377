import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import CONFIG from '../../../config';
import { EvidenciaObacService } from 'src/app/services/evidencia-obac.service';

@Component({
  selector: 'app-visualizar-imagen-obac',
  templateUrl: './visualizar-imagen-obac.page.html',
  styleUrls: ['./visualizar-imagen-obac.page.scss'],
})
export class VisualizarImagenObacPage implements OnInit {

  evidencia: any = {}
  idincidenciaMR = 0
  evidenciasMR = []
  rutaImg = CONFIG.API + '/imagesobac/'

  constructor(public navParams: NavParams, public modalCtrl: ModalController
    ,public evidenciaOBAC: EvidenciaObacService) {
    this.cargarDatos()
  }

  ngOnInit() {
  }

  cargarDatos() {
    //console.log(this.navParams);
     this.evidencia = this.navParams.get('evidencia');
    if (this.evidencia.evidenciaOBAC) {
       

      this.evidenciaOBAC.verEvidenciasXReporte(this.navParams.get('idincidenciaOBAC'))
      .then((imagenes: any) => {
        this.evidenciasMR = imagenes
      })
      this.evidencia.ruta = this.rutaImg + this.navParams.get('evidencia').imagen;
    } 
  }


  async cerrar() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}