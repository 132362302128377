import { Component, OnInit } from '@angular/core';
import { IncidenciaMRServiceSqlliteService } from 'src/app/services/incidencia-mrservice-sqllite.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-offline-incidencias-mr',
  templateUrl: './popover-offline-incidencias-mr.page.html',
  styleUrls: ['./popover-offline-incidencias-mr.page.scss'],
})
export class PopoverOfflineIncidenciasMrPage implements OnInit {

  incidencias:any=[]
  constructor(
    private incidenciMRSqlLite:IncidenciaMRServiceSqlliteService,
    private popoverController:PopoverController
  ) 
  {
    this.incidenciMRSqlLite.getAllIncidencias()
    .then((incidencias)=>{
      this.incidencias=incidencias;
    })
   }

  ngOnInit() {
  }

  seleccionarIncidencia(incidencia){
    this.popoverController.dismiss(incidencia)
  }

}
